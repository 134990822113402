import type * as Types from '../../graphql/base-types.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GqlDocumentGenStateQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID']['input'];
  id: Types.Scalars['ID']['input'];
}>;


export type GqlDocumentGenStateQuery = { document: { id: string, genState: Types.GqlDocGenState } };


export const DocumentGenStateDocument = gql`
    query DocumentGenState($companyId: ID!, $id: ID!) {
  document(companyId: $companyId, id: $id) {
    id
    genState
  }
}
    `;

/**
 * __useDocumentGenStateQuery__
 *
 * To run a query within a React component, call `useDocumentGenStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentGenStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentGenStateQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentGenStateQuery(baseOptions: Apollo.QueryHookOptions<GqlDocumentGenStateQuery, GqlDocumentGenStateQueryVariables> & ({ variables: GqlDocumentGenStateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlDocumentGenStateQuery, GqlDocumentGenStateQueryVariables>(DocumentGenStateDocument, options);
      }
export function useDocumentGenStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlDocumentGenStateQuery, GqlDocumentGenStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlDocumentGenStateQuery, GqlDocumentGenStateQueryVariables>(DocumentGenStateDocument, options);
        }
export function useDocumentGenStateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GqlDocumentGenStateQuery, GqlDocumentGenStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GqlDocumentGenStateQuery, GqlDocumentGenStateQueryVariables>(DocumentGenStateDocument, options);
        }
export type DocumentGenStateQueryHookResult = ReturnType<typeof useDocumentGenStateQuery>;
export type DocumentGenStateLazyQueryHookResult = ReturnType<typeof useDocumentGenStateLazyQuery>;
export type DocumentGenStateSuspenseQueryHookResult = ReturnType<typeof useDocumentGenStateSuspenseQuery>;
export type DocumentGenStateQueryResult = Apollo.QueryResult<GqlDocumentGenStateQuery, GqlDocumentGenStateQueryVariables>;