import { Stack, Tooltip, Typography, TypographyProps } from '@mui/joy';

export const LabeledText = ({
  label,
  children,
  textColor,
  tooltip,
}: {
  label: React.ReactNode;
  children: React.ReactNode;
  textColor?: TypographyProps['textColor'];
  tooltip?: React.ReactNode;
}) => {
  return (
    <Stack>
      <Tooltip title={tooltip} placement="top">
        <Typography fontSize="10px" textColor={textColor}>
          {label}
          {tooltip && '*'}
        </Typography>
      </Tooltip>
      <Typography textColor={textColor}>{children}</Typography>
    </Stack>
  );
};
