import { Trans } from '@lingui/react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  FileUploadInput,
  Checkbox,
  DialogActions,
  Button,
  GenericError,
} from '@startuptools/ui';
import { Accept } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';

export type UploadDocForm = {
  file: File;
  signDigitally: boolean;
};

export const defaultAccept = { 'application/pdf': ['.pdf'] };

export const archiveAccept = {
  'application/zip': ['.zip'],
  'x-zip-compressed': ['.zip'],
  'application/x-tar': ['.tar'],
  'application/x-bzip': ['.bz'],
  'application/x-bzip2': ['.bz2'],
  'application/gzip': ['.gz'],
  'application/vnd.rar': ['.rar'],
  'application/x-7z-compressed': ['.7z'],
};

export const imageAccept = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/svg+xml': ['.svg'],
  'image/webp': ['.webp'],
  'image/avif': ['.avif'],
  'image/tiff': ['.tif', '.tiff'],
};

export const documentAccept = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/msword': ['.doc'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.oasis.opendocument.presentation': ['.odp'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
  'application/vnd.oasis.opendocument.text': ['odt'],
  'text/plain': ['.txt'],
  'application/rtf': ['.rtf'],
};

export const allAccept = {
  ...defaultAccept,
  ...documentAccept,
  ...imageAccept,
  ...archiveAccept,
};

export const DocumentCreateDialog = ({
  onClose,
  upload,
  showExistingDocWarning,
  showSigningWarning,
  mode,
  allowDigitalSignature,
  acceptMimeTypes = defaultAccept,
}: {
  onClose: () => void;
  upload: (data: UploadDocForm) => Promise<unknown>;
  showExistingDocWarning: boolean;
  showSigningWarning: boolean;
  mode: 'upload' | 'generate';
  allowDigitalSignature: boolean;
  acceptMimeTypes?: Accept;
}) => {
  const { register, handleSubmit, control, formState, clearErrors, watch } = useForm<UploadDocForm>();
  const file = watch('file');
  const isUploadedPdf = mode === 'upload' && file?.type === 'application/pdf';

  return (
    <Dialog loading={formState.isSubmitting}>
      <DialogTitle onClose={onClose}>
        {mode === 'upload' ? <Trans id="Upload File" /> : <Trans id="Create Document" />}
      </DialogTitle>
      <form onSubmit={handleSubmit(upload)}>
        <DialogContent>
          {(showExistingDocWarning || showSigningWarning) && (
            <Alert color="warning">
              {showSigningWarning ? (
                <Trans id="A signature process is already ongoing. If you continue, the old process will be cancelled." />
              ) : (
                <Trans id="A document has already been created. Continuing here will replace the document." />
              )}
            </Alert>
          )}

          {mode === 'upload' && (
            <Controller
              name="file"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange } }) => {
                return (
                  <FileUploadInput
                    onChange={e => {
                      clearErrors();
                      return onChange(e);
                    }}
                    invalid={Boolean(formState.errors.file)}
                    acceptMimeTypes={acceptMimeTypes}
                  />
                );
              }}
            />
          )}
          {(mode === 'generate' || isUploadedPdf) && allowDigitalSignature && (
            <Checkbox
              label={<Trans id="The document will be signed digitally" />}
              defaultChecked
              {...register('signDigitally')}
            />
          )}
          {formState.errors.file && (
            <Alert color="warning">
              <Trans id="Select a file before you continue" />
            </Alert>
          )}
          {formState.isSubmitted && !formState.isSubmitSuccessful && !formState.errors.file && <GenericError />}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} disabled={formState.isSubmitting}>
            <Trans id="Cancel" />
          </Button>
          <Button type="submit" disabled={formState.isSubmitting}>
            <Trans id="Continue" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
