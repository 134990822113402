import { Transform } from 'class-transformer';
import { DateTime } from 'luxon';
import { isNil } from 'lodash-es';
import { MeetingConcern } from './meeting-concern.model';
import { AgendaItem } from './agenda-item.model';
import {
  GqlMeeting,
  GqlMeetingHeldKind,
  GqlMeetingKind,
  GqlMeetingStatus,
  GqlMeetingSummonStatus,
} from '../graphql/operations';
import { AutoFields } from '.';
import { Company } from './company.model';

export const MeetingKindLabel: Record<GqlMeetingKind, string> = {
  [GqlMeetingKind.BoardMeeting]: $localize`Styrelsemöte`,
  [GqlMeetingKind.ShareholdersMeeting]: $localize`Ordinarie bolagsstämma`,
  [GqlMeetingKind.ExtraShareholdersMeeting]: $localize`Extra bolagsstämma`,
};

export const meetingHeldKindLabel: Record<GqlMeetingHeldKind, string> = {
  [GqlMeetingHeldKind.Physical]: $localize`Fysiskt möte`,
  [GqlMeetingHeldKind.PhysicalAndPhone]: $localize`Fysiskt möte och Telefon/video`,
  [GqlMeetingHeldKind.Phone]: $localize`Telefon/video-möte`,
  [GqlMeetingHeldKind.PerCapsulam]: $localize`Per Capsulam`,
};

export interface MeetingWithIncludes {
  model: Meeting;
  agendaItems: AgendaItem[];
  meetingConcerns: MeetingConcern[];
}

export type MeetingId = string;

export class Meeting
  implements
    Omit<
      GqlMeeting,
      | 'meetingTodos'
      | 'meetingDocuments'
      | 'meetingTodos'
      | 'agendaItems'
      | 'company'
      | 'meetingConcerns'
      | 'meetingParticipants'
      | 'meetingAppendices'
      | 'summonsAttachments'
    >
{
  id: MeetingId;
  summonStatus: GqlMeetingSummonStatus;
  number?: string;
  startTime: string;
  endTime?: string;
  kind: GqlMeetingKind;
  place: string;
  phone: string;
  companyId: string;
  company: Company;
  primaryLang: boolean;
  secondaryLang: boolean;
  approvedByMeeting?: string | null;
  createdByMeeting?: string;
  heldKind: GqlMeetingHeldKind;
  registerAttendance?: boolean;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  registerAttendanceDate?: DateTime;
  contactEmail?: string;
  postalVote: boolean;
  boardsRecommendation: boolean;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  postalVoteDate?: DateTime;
  postalVoteEmail?: string;
  usesStepper: boolean;
  agendaItemsDone: boolean;
  status: GqlMeetingStatus;

  get startTimeAsLuxon(): DateTime {
    return DateTime.fromISO(this.startTime);
  }

  // @ts-expect-error TS2366
  // eslint-disable-next-line getter-return
  get endTimeAsLuxon(): DateTime {
    if (!isNil(this.endTime)) {
      return DateTime.fromISO(this.endTime);
    }
  }

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(
    seed?: Omit<Meeting, AutoFields | 'startTimeAsLuxon' | 'endTimeAsLuxon' | 'meetingTodos' | 'meetingDocuments'>,
  ) {
    Object.assign(this, seed);
  }
}
