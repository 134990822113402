export enum DocGenRelationName {
  MeetingId = 'meeting_id',
  ShareIssueId = 'share_issue_id',
  ArticlesOfAssocId = 'articles_of_assoc_id',
  WarrantProgramId = 'warrant_program_id',
  AuthorizationGroupId = 'authorization_group_id',
  ShareTransactionId = 'share_transaction_id',
  WarrantParticipantId = 'warrant_participant_id',
  ShareIssueParticipantId = 'share_issue_participant_id',
  OptionsRegistrationId = 'options_registration_id',
  OptionsSharesSubscriberId = 'options_shares_subscriber_id',
  OptionsSharesRegistrationId = 'options_shares_registration_id',
  MeetingSummonsAttachment = 'meeting_summons_attachment',
}

export interface DocGenRelation2 {
  relation_id: string;
  relation_name: DocGenRelationName;
}
