import {
  GqlShareClass,
  GqlShareClassInput,
  GqlShareClassWithMinMax,
  GqlShareClassWithMinMaxInput,
} from '../graphql/base-types.graphql';

export const shareClassWithMinMaxToInput = ({
  voteWeight,
  quantity,
  name,
  max,
  min,
}: GqlShareClassWithMinMax): GqlShareClassWithMinMaxInput => {
  return { voteWeight, quantity, name, min, max };
};

export const shareClassToInput = ({ name, quantity, voteWeight }: GqlShareClass): GqlShareClassInput => {
  return { name, quantity, voteWeight };
};
