import { ChangeDetectionStrategy, Component } from '@angular/core';
import { JwtService } from '@startuptools/angular/auth';
import { AuthWrapperComponent } from './auth-wrapper.component';
import { InMemoryCacheService } from '../common/apollo-memory-cache';
import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { environment, host } from '../../environments/environment';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, AuthWrapperComponent],
  template: `<app-auth-wrapper
    [appPage]="true"
    imgSrc="/assets/images/people_working.png"
    [apolloClient]="apolloClient"
  ></app-auth-wrapper> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthComponent {
  public apolloClient = new ApolloClient({
    cache: this.inMemoryCache.cache,
    connectToDevTools: !environment.production,
    link: ApolloLink.from([
      setContext(() => ({
        headers: { Authorization: `Bearer ${this.jwtService.jwt}` },
      })),
      new HttpLink({
        uri: new URL('auth-service/graphql', host.clientUrl).href,
      }),
    ]),
  });

  constructor(
    private jwtService: JwtService,
    private inMemoryCache: InMemoryCacheService,
  ) {}
}
