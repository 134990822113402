import React from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { Box, Button, Typography, Stack } from '@mui/joy';
import { Trans, useLingui } from '@lingui/react';
import { NumberFormat } from './NumberFormat';

const MAX_FILE_SIZE = 20_000_000;

export const FileUploadInput = ({
  onChange,
  invalid,
  acceptMimeTypes,
}: {
  onChange: (file: File) => void;
  invalid: boolean;
  acceptMimeTypes: Accept;
}) => {
  const { i18n } = useLingui();
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      const [file] = acceptedFiles;
      if (file) {
        onChange?.(file);
      }
    },
    [onChange],
  );

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, fileRejections } = useDropzone({
    onDrop,
    accept: acceptMimeTypes,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
  });

  const error = invalid ?? fileRejections.length > 0;

  const uploadedFile = acceptedFiles[0];

  return (
    <Box
      {...getRootProps()}
      p={2}
      border={2}
      borderRadius={1}
      borderColor={isDragActive ? 'primary.400' : error ? 'warning.400' : 'neutral.outlinedBorder'}
      bgcolor="background.level1"
      minHeight={100}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        borderStyle: 'dashed',
        cursor: 'pointer',
        transition: 'border .3s ease-in-out',
      }}
    >
      <input {...getInputProps()} />
      {uploadedFile ? (
        <Stack spacing={2} alignItems="center">
          <Typography level="body-md">{uploadedFile.name}</Typography>
          <Typography>
            <NumberFormat locale={i18n.locale} variant="filesize">
              {uploadedFile.size}
            </NumberFormat>
          </Typography>
          <Box>
            <Button variant="outlined" color="neutral">
              <Trans id="Replace" />
            </Button>
          </Box>
        </Stack>
      ) : (
        <Stack spacing={2} alignItems="center">
          <Typography level="body-md">
            {isDragActive ? <Trans id="Right here!" /> : <Trans id="Drag and drop a file here." />}
          </Typography>
          <Box>
            <Button>
              <Trans id="Or Click Here" />
            </Button>
          </Box>
          <Typography level="body-sm">
            <Trans
              id="Filesize limit: <maxFilesize></maxFilesize>"
              components={{
                maxFilesize: (
                  <NumberFormat locale={i18n.locale} variant="filesize">
                    {MAX_FILE_SIZE}
                  </NumberFormat>
                ),
              }}
            />
          </Typography>
          {acceptMimeTypes && (
            <Typography
              level="body-sm"
              fontWeight={fileRejections.length > 0 ? 'bold' : 'normal'}
              color={fileRejections.length > 0 ? 'warning' : 'neutral'}
            >
              <Trans
                id="Allowed file types: {extensions}"
                values={{
                  extensions: Object.values(acceptMimeTypes)
                    .flat()
                    .map(ext => `*${ext}`)
                    .join(', '),
                }}
              />
            </Typography>
          )}
        </Stack>
      )}
    </Box>
  );
};
