import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Angulartics2Hubspot } from 'angulartics2';
import { SubSink } from 'subsink';
import { distinctUntilChanged, of, shareReplay } from 'rxjs';
import { isNil } from 'lodash-es';
import { LoadingKind, LoadingDirective } from '@startuptools/angular/loading';
import { AlertDialogComponent } from './components/simple-dialogs/alert-dialog.component';
import { Platform } from '@angular/cdk/platform';
import { Store, select } from '@ngrx/store';
import { selectCompany } from './store/selectors/companies.base';
import { selectCurrentUser } from './store/selectors/user.selectors';
import { AppState } from './store/reducers';
import { Hubspot } from './components/hubspot/Hubspot';
import { JwtService } from '@startuptools/angular/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {
  Hubspot = Hubspot;
  LoadingKind = LoadingKind;
  @ViewChild('qLoading') qLoading: LoadingDirective;
  subs = new SubSink();
  oldBrowser$ = of(isNil(File) || isNil(globalThis) || isNil([].toReversed));

  company$ = this.store.pipe(select(selectCompany), distinctUntilChanged(), shareReplay(1));
  user$ = this.store.pipe(select(selectCurrentUser), distinctUntilChanged(), shareReplay(1));

  constructor(
    angulartics2Hubspot: Angulartics2Hubspot,
    router: Router,
    dialog: MatDialog,
    public platform: Platform,
    private store: Store<AppState>,
    public jwtService: JwtService,
  ) {
    angulartics2Hubspot.startTracking();
    this.subs.sink = router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.qLoading?.start();
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationError ||
        event instanceof NavigationCancel
      ) {
        this.qLoading?.stop();
      }
    });

    this.subs.sink = router.events.subscribe(event => {
      if (event instanceof NavigationError && event.error?.toString().startsWith('ChunkLoadError')) {
        AlertDialogComponent.open(dialog, {
          title: $localize`Sidan behöver laddas om`,
          text: $localize`Det finns en uppdatering och sidan behöver laddas om`,
          ok: $localize`Ladda om`,
        })
          .beforeClosed()
          .subscribe(() => {
            window.location.reload();
          });
      }
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
