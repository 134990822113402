import { isNil, map, toNumber } from 'lodash-es';
import { DateTime } from 'luxon';
import { Transform } from 'class-transformer';
import type { Meeting } from './meeting.model';
import type { SnrCase } from './snr-case.model';
import { GqlArticlesOfAssoc } from '../graphql/operations';
import { GqlDigitalAgm } from '../graphql/base-types.graphql';

export enum FiscalYears {
  '0101-1231',
  '0201-0131',
  '0301-0228',
  '0401-0331',
  '0501-0430',
  '0601-0531',
  '0701-0630',
  '0801-0731',
  '0901-0831',
  '1001-0930',
  '1101-1031',
  '1201-1130',
}

export interface AoaWithIncludes {
  model: ArticlesOfAssoc;
  meeting?: Meeting;
  snrCase?: SnrCase;
}

export class ArticlesOfAssoc implements Omit<GqlArticlesOfAssoc, 'articlesOfAssocDocuments' | 'company'> {
  id: string;

  companyId: string;
  snrCaseId?: string;
  contactId?: string;
  signatoryId?: string;
  regFormPaymentThroughBolago: boolean;

  changeCompanyName = false;
  readonly companyName: string;
  newCompanyNames?: string[];

  changeSeat = false;
  seat: string;

  changeKindOfBusiness = false;
  kindOfBusiness: string;

  defaultFileName: string;

  changeBoardLimits = false;
  minimumNumberOfBoardMembers: string;
  maximumNumberOfBoardMembers: string;
  minimumNumberOfSubstitutes: string;
  maximumNumberOfSubstitutes: string;

  changeShareLimits = false;
  minimumShareCapital: string;
  maximumShareCapital: string;
  minimumNumberOfShares: string;
  maximumNumberOfShares: string;

  changeNumberOfAuditors = false;
  minimumNumberOfAuditors: string;
  maximumNumberOfAuditors: string;
  minimumNumberOfSubstituteAuditors: string;
  maximumNumberOfSubstituteAuditors: string;

  changeSummoning = false;
  summoningTimeMin: string;
  summoningTimeMax: string;
  summoningMethodMail = true;
  summoningMethodEmail = false;
  summoningMethodFax = false;
  summoningMethodPoit = false;
  summoningMethodNewspaper?: string;
  summoningMethodOtherPrimaryLang?: string;

  changeDigitalAgm: boolean;
  digitalAgm: GqlDigitalAgm;

  agendaItemsAgmHtml: { primaryLang: string; secondaryLang: string }[];

  changeFiscalYear = false;
  fiscalYear: string;

  changeNumberOfShares = false;
  readonly numberOfSharesBefore: string;
  numberOfSharesAfter: string;
  get possibleMinimumShares(): number {
    if (!isNil(this.numberOfSharesAfter)) {
      return toNumber(this.numberOfSharesAfter);
    } else {
      return toNumber(this.minimumNumberOfShares);
    }
  }

  shareCapitalBefore: string;

  changePostTransferPurchaseRight: boolean;
  changeRightOfFirstRefusal: boolean;
  changeConsent: boolean;
  changeMajority: boolean;
  changeArbitrator: boolean;

  havePostTransferPurchaseRight = false;
  haveRightOfFirstRefusal = false;
  haveConsent = false;
  haveMajority = false;
  haveArbitrator = false;
  postTransferPurchaseRightPrimaryLang?: string;
  rightOfFirstRefusalPrimaryLang?: string;
  consentPrimaryLang?: string;
  majorityPrimaryLang?: string;
  arbitratorPrimaryLang?: string;

  quotaBefore?: string | null | undefined;
  quotaAfter?: string | null | undefined;

  @Transform(({ value }: { value: string }) => DateTime.fromISO(value), {
    toClassOnly: true,
  })
  readonly createdAt: DateTime;

  @Transform(({ value }: { value: string }) => DateTime.fromISO(value), {
    toClassOnly: true,
  })
  readonly updatedAt: DateTime;

  get needRegistration(): boolean {
    return (
      this.changeBoardLimits ||
      this.changeCompanyName ||
      this.changeFiscalYear ||
      this.changeKindOfBusiness ||
      this.changeNumberOfAuditors ||
      this.changeNumberOfShares ||
      this.changeSeat ||
      this.changeShareLimits ||
      this.changeSummoning
    );
  }

  constructor(seed?: Partial<ArticlesOfAssoc>) {
    Object.assign(this, seed);
  }

  getStatusText(meeting?: Meeting, snrCase?: SnrCase): string {
    if (!isNil(snrCase)) {
      return map(snrCase.status, s => s.value).join(', ');
    } else if (!isNil(meeting)) {
      return '';
    }
    return $localize`Utkast`;
  }
}
