import type * as Types from '../base-types.graphql';

import { gql } from '@apollo/client';
export type GqlDocFieldsFragment = { id: string, companyId: string, mimeType: string, filename: string, filesize?: number | null, sha256?: string | null, signedFilesize?: number | null, signedSha256?: string | null, signatureMethod?: Types.GqlDocumentSignatureMethod | null, dirty: boolean, scriveMtime?: string | null, scriveCtime?: string | null, scriveStatus?: Types.GqlScriveDocumentStatus | null, scriveParties?: any | null, scriveTimeoutTime?: string | null, zignedAgreementStatus?: Types.GqlZignedAgreementStatus | null, signAllowedAction: Types.GqlSignAllowedAction, hasSignStatus: boolean, hasThumbnail: boolean, kind: Types.GqlDocSystemTagKind, groupId?: string | null, userTags?: Array<string> | null, genState: Types.GqlDocGenState, uploaded: boolean, reminderSentAt?: string | null, deletable: boolean, createdAt: string, updatedAt: string };

export const DocFieldsFragmentDoc = gql`
    fragment DocFields on Document {
  id
  companyId
  mimeType
  filename
  filesize
  sha256
  signedFilesize
  signedSha256
  signatureMethod
  dirty
  scriveMtime
  scriveCtime
  scriveStatus
  scriveParties
  scriveTimeoutTime
  zignedAgreementStatus
  signAllowedAction
  hasSignStatus
  hasThumbnail
  kind
  groupId
  userTags
  genState
  uploaded
  reminderSentAt
  deletable
  createdAt
  updatedAt
}
    `;