import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs';
import { isNil } from 'lodash-es';
import { AppState } from '../../store/reducers';
import { selectCompanyId } from '../../store/selectors/companies.base';
import { Toolbar } from './Toolbar';
import { ReactWrapperComponent } from '../react-wrapper/react-wrapper.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-toolbar',
  template: `<app-react-wrapper [component]="Toolbar" [companyId]="companyId$ | async" />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactWrapperComponent],
})
export class ToolbarComponent {
  Toolbar = Toolbar;

  companyId$ = this.store.pipe(
    select(selectCompanyId),
    filter(e => !isNil(e)),
  );

  constructor(private store: Store<AppState>) {}
}
