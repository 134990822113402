import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { isNil, trim, isEmpty } from 'lodash-es';

const jwtHelper = new JwtHelperService();
export const AUTH_JWT_KEY = 'app.auth_jwt';

interface QJwtToken {
  sub: string;
  admin: boolean;
  exp: number;
}

const MINUTE = 60000;

@Injectable({ providedIn: 'root' })
export class JwtService {
  private _decoded?: QJwtToken | null;

  constructor() {
    try {
      if (!isNil(this.jwt)) {
        this._decoded = jwtHelper.decodeToken(this.jwt);
      }
    } catch (e) {
      this.clear();
    }
  }

  get jwt(): string | undefined {
    const jwt = trim(localStorage.getItem(AUTH_JWT_KEY) || '', '"');
    try {
      // Try to decode to make sure it's a JWT at all, otherwize clear and return undefined
      jwtHelper.decodeToken(jwt);
    } catch (e) {
      console.error(e);
      this.clear();
      // eslint-disable-next-line getter-return
      return;
    }
    return jwt;
  }

  set jwt(jwt: string) {
    // Remove old token if there is one, keep this for a few years
    localStorage.removeItem('qoorp.x_jwt');
    try {
      this._decoded = jwtHelper.decodeToken(jwt);
    } catch (e) {
      console.error(e);
      this.clear();
      throw e;
    }
    localStorage.setItem(AUTH_JWT_KEY, jwt);
  }

  get sub(): string | undefined {
    if (!isEmpty(this.jwt)) {
      return this._decoded?.sub;
    }
    return undefined;
  }

  get admin(): boolean {
    if (!isEmpty(this.jwt)) {
      return this._decoded?.admin ?? false;
    }
    return false;
  }

  get expMillis(): number | undefined {
    if (!isNil(this._decoded) && !isEmpty(this.jwt)) {
      return this._decoded?.exp * 1000;
    }
    return undefined;
  }

  clear() {
    this._decoded = undefined;
    localStorage.removeItem(AUTH_JWT_KEY);
  }

  isValid(): boolean {
    if (isEmpty(this.jwt) || isNil(this.expMillis)) {
      return false;
    }
    const diff = this.expMillis - new Date().getTime() - MINUTE * 2;
    return diff > 0;
  }
}
