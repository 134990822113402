import { Injectable } from '@angular/core';
import { Cache, InMemoryCache, InMemoryCacheConfig, Reference } from '@apollo/client';
import { Store } from '@ngrx/store';
import { graphqlActions } from '../store/actions/graphql.actions';
import { AppState } from '../store/reducers';

class CustomInMemoryCache extends InMemoryCache {
  /***
   ** This class makes is so that whatever is fetched inside react also gets updated in ngrx
   */
  constructor(
    private store: Store<AppState>,
    config: InMemoryCacheConfig = {},
  ) {
    super(config);
  }

  private partialQueriesThatShouldBeIgnored = [
    'StepOsrSubscribers',
    'StepOrSubscribers',
    'TransactionsView',
    'TransactionsDialog',
    'Dashboard',
    'CreateTransaction',
    'ShareTransactionGroupView',
    'SideNavUser',
  ].join('|');

  public write(options: Cache.WriteOptions): Reference | undefined {
    if (!options?.query?.loc?.source.body.match(this.partialQueriesThatShouldBeIgnored)) {
      this.store.dispatch(graphqlActions.result({ payload: { opId: 'none', result: { data: options.result } } }));
    }
    return super.write(options);
  }
}

@Injectable({ providedIn: 'root' })
export class InMemoryCacheService {
  private _instance: InMemoryCache;

  constructor(private store: Store<AppState>) {}

  public init(config: InMemoryCacheConfig = {}) {
    if (this._instance) {
      return;
    }
    this._instance = new CustomInMemoryCache(this.store, config);
  }

  get cache() {
    return this._instance;
  }
}
