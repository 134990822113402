import { Trans, useLingui } from '@lingui/react';
import { useZignedDocumentsSuspenseQuery } from './ZignedDocuments.graphql';
import { useForm } from 'react-hook-form';
import { useDebounce } from '@startuptools/common/react-hooks';
import {
  Card,
  CircularProgress,
  CloseIcon,
  ErrorBoundary,
  IconButton,
  Input,
  SearchIcon,
  Stack,
  Table,
} from '@startuptools/ui';
import { Suspense } from 'react';
import { ZignedDocumentRow } from './ZignedDocumentRow';

const AdminZignedDocumentsTable = ({ searchString }: { searchString: string }) => {
  const {
    data: { adminZignedDocuments },
  } = useZignedDocumentsSuspenseQuery({
    variables: { input: { filter: searchString, pageLimit: 500, pageOffset: 0 } },
    fetchPolicy: 'cache-and-network',
  });
  return (
    <>
      <Card sx={{ width: '100%' }}>
        <Table sx={{ tableLayout: 'auto', width: '100%' }}>
          <thead>
            <tr>
              <th />
              <th>
                <Trans id="Filename" />
              </th>
              <th>
                <Trans id="Status" />
              </th>
              <th>
                <Trans id="Updated At" />
              </th>
              <th>
                <Trans id="Reminder Sent At" />
              </th>
              <th align="right" />
            </tr>
          </thead>
          <tbody>
            {adminZignedDocuments.map(doc => (
              <ZignedDocumentRow key={doc.id} doc={doc} />
            ))}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export const AdminZignedDocuments = () => {
  const { i18n } = useLingui();
  const { register, watch, setValue } = useForm<{ searchString: string }>({ defaultValues: { searchString: '' } });
  const searchString = watch('searchString');
  const debouncedSearchString = useDebounce(searchString);

  return (
    <Stack gap={2} alignItems="center">
      <Input
        fullWidth
        {...register('searchString')}
        startDecorator={searchString === debouncedSearchString ? <SearchIcon /> : <CircularProgress size="sm" />}
        endDecorator={
          searchString?.length ? (
            <IconButton onClick={() => setValue('searchString', '')}>
              <CloseIcon />
            </IconButton>
          ) : undefined
        }
        type="search"
        placeholder={i18n._('Search...')}
      />
      <ErrorBoundary>
        <Suspense>
          <AdminZignedDocumentsTable searchString={debouncedSearchString} />
        </Suspense>
      </ErrorBoundary>
    </Stack>
  );
};
