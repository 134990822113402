import { Transform } from 'class-transformer';
import { DateTime } from 'luxon';
import { isNil } from 'lodash-es';
import { AutoFields } from '.';
import { GqlSnrShareCapitalChange } from '../graphql/operations';
import { GqlShareClass } from '../graphql/base-types.graphql';

export class SnrShareCapitalChange implements Omit<GqlSnrShareCapitalChange, 'sharesLeftToAllocateByShareClass'> {
  id: string;

  name: string;
  serialNumber: string;
  filingYear: string;
  filingNumber: string;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  registrationDate: DateTime;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  decisionDate: DateTime;
  currency: string;
  amountTotal: string;
  amountChange: string;
  sharesChange: string;
  sharesTotal: string;
  sharePriceFactor: string;
  sharesTotalSplitAdjusted: string;
  transactionValue: string;
  shareValue: string;
  companyValue: string;
  sharesTotalAfterChange: string;
  foundersShareFraction: string;
  sharesChangeSplitAdjusted: string;
  companyValuePreMoney: string;
  amountTotalBeforeChange: string;
  sharesLeftToAllocate: number;

  shareValueSplitAdjusted: string;
  shareClasses: GqlShareClass[];

  done: boolean;
  decisionDateWarning: boolean;

  sortOrderSnr: number | null;
  sortOrderOverride: number | null;

  companyId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed: Omit<SnrShareCapitalChange, AutoFields | 'splitFactor' | 'splitSharesChange' | 'sortOrder'>) {
    Object.assign(this, seed);
  }

  splitFactor: string;
  splitSharesChange: string;
  sortOrder: number | null;
}
