import { Trans, useLingui } from '@lingui/react';
import {
  Alert,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
  DialogActions,
  DialogContent,
  WarningIcon,
} from '@startuptools/ui';
import { GqlSeverity, GqlValidatorStatus } from '../graphql/base-types.graphql';

import { DangerousOutlined } from '@mui/icons-material';

export const ValidatorErrorDialog = ({
  children,
  onClose,
  preview,
  hasFatalErrors,
}: {
  children: React.ReactNode;
  onClose: () => void;
  preview: () => void;
  hasFatalErrors: boolean;
}) => {
  return (
    <Modal open onClose={onClose}>
      <ModalDialog>
        <ModalClose />
        <DialogContent>
          <Typography level="h4">
            <Trans id="Errors were detected" />
          </Typography>
          <Alert>
            <Trans id="There are one or multiple problems that needs resolving before generating the document." />
            {' ' /* here on purpose*/}
            {!hasFatalErrors && <Trans id="You can view a Preview of the document, but it will be incomplete." />}
          </Alert>

          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            <Trans id="Cancel" />
          </Button>
          {!hasFatalErrors && (
            <Button onClick={preview}>
              <Trans id="Preview" />
            </Button>
          )}
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export const ValidatorError = ({ error }: { error: GqlValidatorStatus & { action?: React.ReactNode } }) => {
  const { i18n } = useLingui();
  const icon = error.severity === GqlSeverity.Warning ? <WarningIcon /> : <DangerousOutlined />;
  const color = error.severity === GqlSeverity.Warning ? 'warning' : 'danger';
  return (
    <Alert
      key={error.error?.en.short}
      sx={{ alignItems: 'flex-start' }}
      startDecorator={icon}
      variant="soft"
      color={color}
      endDecorator={error.action}
    >
      <div>
        <div>{error.error?.[i18n.locale as 'sv' | 'en']?.short}</div>
        <Typography level="body-sm" color={color}>
          {error.error?.[i18n.locale as 'sv' | 'en']?.long}
        </Typography>
      </div>
    </Alert>
  );
};
