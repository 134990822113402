import {
  Modal,
  ModalDialog,
  ModalClose,
  Typography,
  Stack,
  Autocomplete,
  FormControl,
  CircularProgress,
  Chip,
  CloseIcon,
  Divider,
  Alert,
  GenericError,
} from '@startuptools/ui';
import { DisplayDocument, DocumentDialog as DocumentDialogDisplay } from '@startuptools/business-ui';
import { DocumentSignatures } from '../document-signatures/DocumentSignatures';
import {
  DocumentUserTagsDocument,
  useDocSetTagsMutation,
  useDocumentQuery,
  useDocumentSuspenseQuery,
  useDocumentUserTagsSuspenseQuery,
} from '../../graphql/react-operations';
import { useCompanyId } from '@startuptools/common/react-wrapper';
import { Suspense, useEffect, useMemo } from 'react';
import { Trans, useLingui } from '@lingui/react';
import { isString, compact, uniqBy, uniq } from 'lodash-es';
import { createFilterOptions } from '@mui/base';
import { GqlDocGenState, GqlDocument, GqlDocumentSignatureMethod } from '../../graphql/base-types.graphql';

interface TagSelect {
  inputValue?: string;
  title: string;
}

const suggestedUserTags: TagSelect[] = [
  'Anställningsavtal',
  'Aktieägaravtal',
  'NDA',
  'Avtal med leverantör',
  'Avtal med partner',
  'Överlåtelse av aktier',
].map(s => ({ title: s }));

const filter = createFilterOptions<TagSelect>();

export const TagsInput = ({ document }: { document: Pick<GqlDocument, 'id' | 'userTags' | 'hasThumbnail'> }) => {
  const companyId = useCompanyId();
  const { i18n } = useLingui();
  const {
    data: { documentUserTags },
  } = useDocumentUserTagsSuspenseQuery({ variables: { companyId } });
  const [doSetTags, { loading }] = useDocSetTagsMutation({ refetchQueries: [DocumentUserTagsDocument] });
  const documentTags: TagSelect[] = useMemo(
    () => document.userTags?.map(t => ({ title: t })) ?? [],
    [document.userTags],
  );

  const userTags: TagSelect[] = useMemo(() => documentUserTags.tags.map(s => ({ title: s })), [documentUserTags.tags]);
  const tags = useMemo(
    () => uniqBy([...userTags, ...suggestedUserTags], t => t.title).filter(t => !document.userTags?.includes(t.title)),
    [userTags, document.userTags],
  );

  return (
    <FormControl sx={{ width: '100%' }}>
      <Autocomplete
        multiple
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={tags}
        disabled={loading}
        endDecorator={loading && <CircularProgress size="sm" />}
        value={documentTags}
        onChange={async (_, newValue) => {
          const vs = newValue.map(t => (isString(t) ? t : t.inputValue || t.title));
          const userTags = uniq(compact(vs));
          await doSetTags({ variables: { companyId, input: { id: document.id, userTags } } });
        }}
        placeholder={i18n._('Add Tag...')}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(option => inputValue === option.title);
          if (inputValue !== '' && !isExisting) {
            filtered.push({ inputValue, title: i18n._('Add {inputValue}', { inputValue }) });
          }
          return filtered;
        }}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option;
          }
          return option.title;
        }}
        renderTags={(tags, getTagProps) =>
          tags.map((item, index) => {
            const { key, ...props } = getTagProps({ index });
            return (
              <Chip
                key={key}
                variant="outlined"
                color="primary"
                endDecorator={<CloseIcon fontSize="small" />}
                {...props}
              >
                {item.title || item.inputValue}
              </Chip>
            );
          })
        }
        sx={{ width: '100%' }}
      />
    </FormControl>
  );
};

// Temporary DialogContent to be used within Angular document-dialog
export const DocumentDialogContent = ({ documentId }: { documentId: string }) => {
  const companyId = useCompanyId();
  const { data, loading, error, stopPolling } = useDocumentQuery({
    variables: { companyId, id: documentId },
    pollInterval: 2000,
  });

  useEffect(() => {
    if (data?.document.genState === GqlDocGenState.Completed || data?.document.genState === GqlDocGenState.Failed) {
      stopPolling();
    }
  }, [data?.document, stopPolling]);

  if (data?.document.genState === GqlDocGenState.Failed) {
    return (
      <Alert>
        <Trans id="Something went wrong while trying to generate the document, please try again. Contact support if problem persists." />
      </Alert>
    );
  }

  if (!data || loading || data?.document.genState !== GqlDocGenState.Completed) {
    return (
      <Stack gap={2} alignItems="center">
        <CircularProgress variant="solid" color="primary" size="lg" />
        <Typography level="h4">
          <Trans id="Generating document..." />
        </Typography>
      </Stack>
    );
  }

  if (error) {
    return <GenericError />;
  }

  return (
    <DocumentDialogDisplay
      document={data.document}
      moreDetails={
        <Stack direction="row" gap={2}>
          <TagsInput document={data.document} />
        </Stack>
      }
    >
      {data.document.signatureMethod === GqlDocumentSignatureMethod.Digital && (
        <>
          <Divider />
          <DocumentSignatures
            documentId={data.document.id}
            scriveStatus={data.document.scriveStatus}
            zignedAgreementStatus={data.document.zignedAgreementStatus}
            signAllowedAction={data.document.signAllowedAction}
          />
        </>
      )}
    </DocumentDialogDisplay>
  );
};

const DocumentContent = ({ document }: { document: DisplayDocument }) => {
  return (
    <DocumentDialogDisplay
      document={document}
      moreDetails={
        <Stack direction="row" gap={2}>
          <TagsInput document={document} />
        </Stack>
      }
    >
      {document.signatureMethod === GqlDocumentSignatureMethod.Digital && (
        <>
          <Divider />
          <DocumentSignatures
            documentId={document.id}
            scriveStatus={document.scriveStatus}
            zignedAgreementStatus={document.zignedAgreementStatus}
            signAllowedAction={document.signAllowedAction}
          />
        </>
      )}
    </DocumentDialogDisplay>
  );
};

const SuspendingDocumentContent = ({ documentId }: { documentId: string }) => {
  const companyId = useCompanyId();
  const { data } = useDocumentSuspenseQuery({ variables: { companyId, id: documentId } });

  return <DocumentContent document={data.document} />;
};

const DocumentModal = ({ children, onClose }: { children: React.ReactNode; onClose: () => void }) => {
  return (
    <Modal open onClose={onClose}>
      <ModalDialog sx={{ maxWidth: '95%', maxHeight: '95%', minWidth: 1000, overflowY: 'auto', paddingX: 2 }}>
        <ModalClose />
        {children}
      </ModalDialog>
    </Modal>
  );
};

export const DocumentDialog = ({ document, onClose }: { document: DisplayDocument; onClose: () => void }) => {
  return (
    <DocumentModal onClose={onClose}>
      <DocumentContent document={document} />
    </DocumentModal>
  );
};

export const SelfLoadingDocumentDialog = ({ documentId, onClose }: { documentId: string; onClose: () => void }) => {
  return (
    <DocumentModal onClose={onClose}>
      <Suspense
        fallback={
          <Stack gap={2} justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        }
      >
        <SuspendingDocumentContent documentId={documentId} />
      </Suspense>
    </DocumentModal>
  );
};
