<form [formGroup]="form" (submit)="submit()">
  <div class="dialog" sutLoading #qLoading="Loading">
    <div class="dialog-close">
      <button type="button" mat-icon-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="dialog-title">
      <app-identity-wrapper [identity]="data.identity" [editable]="false" [showAvatar]="true" />
    </div>
    <div mat-dialog-content class="dialog-content">
      <ng-container>
        <mat-form-field class="w-full" appearance="fill">
          <mat-label i18n>Betalningsmetod</mat-label>
          <input matInput type="text" [value]="paymentKindLabel[formControls.paymentKind.value!]" [readonly]="true" />
        </mat-form-field>
      </ng-container>

      <mat-form-field class="w-full" appearance="fill" *ngIf="showShareClasses$ | async">
        <mat-label i18n>Aktieslag</mat-label>
        <mat-select [formControl]="formControls.shareClass" [compareWith]="compareShareClasses" required>
          <mat-option *ngFor="let sc of shareClasses$ | async" [value]="sc">
            {{ sc.name }} <small>(röstvikt: {{ sc.voteWeight }})</small>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="formControls.shareClass.hasError('required')" i18n>Måste fyllas i</mat-error>
      </mat-form-field>

      <ng-container *ngIf="!data.disableEditQuantity">
        <ng-container *ngIf="(paymentKind$ | async) === PaymentKind.Offset">
          <mat-form-field class="w-full" appearance="fill">
            <mat-label i18n>Fodran</mat-label>
            <input
              matInput
              (focusout)="calcNumberOfOptionsByClaim()"
              type="text"
              [formControl]="formControls.claim"
              [decimalOnly]="true"
              [minNumber]="0"
              localizedNumericInput
              autocomplete="off"
              required
            />
            <mat-error *ngIf="formControls.quantity.hasError('minNumber')" i18n>Minsta belopp är 1 kr</mat-error>
          </mat-form-field>
        </ng-container>

        <mat-form-field class="w-full" appearance="fill">
          <mat-label *ngIf="(wp$ | async)?.warrantKind !== GqlWarrantProgramKind.Nice" i18n>Antal optioner</mat-label>
          <mat-label *ngIf="(wp$ | async)?.warrantKind === GqlWarrantProgramKind.Nice" i18n>Antal NICE</mat-label>
          <input
            matInput
            (focusout)="calcClaimByNumberOfOptions()"
            type="text"
            [formControl]="formControls.quantity"
            localizedNumericInput
            [numericIntegerOnly]="true"
            autocomplete="off"
            [minNumber]="1"
            [maxNumber]="sharesLeftToDistribute$ | async"
            required
          />
          <span matSuffix
            >/ <span (click)="takeAll()" clickable>{{ sharesLeftToDistribute$ | async | number: '1.0' }}</span></span
          >
          <mat-error *ngIf="formControls.quantity.hasError('minNumber')" i18n>minsta möjliga optioner är 1</mat-error>
          <mat-error *ngIf="formControls.quantity.hasError('maxNumber')" i18n
            >max antal optioner är {{ sharesLeftToDistribute$ | async | number }}</mat-error
          >
        </mat-form-field>
      </ng-container>

      <div class="pb-6" *ngIf="!isNil(data.id) && (wp$ | async)?.warrantKind === GqlWarrantProgramKind.Warrant">
        <mat-slide-toggle [formControl]="formControls.forcedRedemption" color="primary"
          >Tvingande återköp</mat-slide-toggle
        >
      </div>

      <ng-container *ngIf="!isNil(data.id)">
        <ng-container *ngIf="wp$ | async as wp">
          <mat-form-field
            class="w-full"
            appearance="fill"
            *ngIf="
              wp?.warrantKind === GqlWarrantProgramKind.Warrant || wp?.warrantKind === GqlWarrantProgramKind.Employee
            "
          >
            <mat-label i18n>Vesting kopplad till</mat-label>
            <mat-select [formControl]="formControls.vestingKind">
              <mat-option [value]="VestingKind.None" *ngIf="wp.warrantKind === GqlWarrantProgramKind.Warrant" i18n
                >Ingen vesting</mat-option
              >
              <mat-option [value]="VestingKind.Employment" i18n>Anställning</mat-option>
              <mat-option [value]="VestingKind.BoardMembership" i18n>Medlemsskap i styrelsen</mat-option>
              <mat-option
                [value]="VestingKind.AdvisoryBoard"
                *ngIf="wp.warrantKind === GqlWarrantProgramKind.Warrant"
                i18n
                >Medlemsskap i bolagets Advisory Board</mat-option
              >
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="formControls.vestingKind.value !== VestingKind.None">
          <mat-form-field class="w-full" appearance="fill">
            <mat-label i18n>Vestingfrekvens</mat-label>
            <mat-select [formControl]="formControls.vestingFrequency">
              <mat-option [value]="VestingFrequency.Monthly" i18n>Månad</mat-option>
              <mat-option [value]="VestingFrequency.Quarterly" i18n>Kvartal</mat-option>
              <mat-option [value]="VestingFrequency.Biyearly" i18n>Halvår</mat-option>
              <mat-option [value]="VestingFrequency.Yearly" i18n>År</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-full" appearance="fill">
            <mat-label i18n>Vestingens längd</mat-label>
            <mat-select [formControl]="formControls.vestingDuration">
              <mat-option *ngFor="let vd of (durationSelectOptions$ | async)?.vestingDuration" [value]="vd.value">
                {{ vd.text }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-full" appearance="fill">
            <mat-label>Cliff</mat-label>
            <mat-select
              [formControl]="formControls.vestingCliffDuration"
              [maxNumber]="formControls.vestingDuration.value"
            >
              <mat-option *ngIf="(wp$ | async)?.warrantKind !== GqlWarrantProgramKind.Employee" [value]="0" i18n
                >Ingen cliff</mat-option
              >
              <mat-option *ngFor="let cd of (durationSelectOptions$ | async)?.cliffDuration" [value]="cd.value">
                {{ cd.text }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formControls.vestingCliffDuration.hasError('maxNumber')" i18n
              >Får inte vara efter vesting</mat-error
            >
          </mat-form-field>

          <ng-container *ngIf="wp$ | async as wp">
            <div
              *ngIf="
                wp.warrantKind === GqlWarrantProgramKind.Warrant || wp.warrantKind === GqlWarrantProgramKind.Employee
              "
              class="flex flex-col gap-2 pb-6"
            >
              <mat-slide-toggle [formControl]="formControls.vestingAcceleration" color="primary"
                >Accelererad vesting</mat-slide-toggle
              >
              <mat-slide-toggle
                *ngIf="wp.warrantKind === GqlWarrantProgramKind.Warrant"
                [formControl]="formControls.repurchaseVested"
                color="primary"
                >Återköp av vestade optioner</mat-slide-toggle
              >
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <mat-form-field class="w-full" appearance="fill">
        <mat-label i18n>Epost</mat-label>
        <input matInput type="email" [formControl]="formControls.email" autocomplete="email" />
      </mat-form-field>
      <mat-form-field class="w-full" appearance="fill">
        <mat-label i18n>Telefon</mat-label>
        <input matInput type="text" [formControl]="formControls.phone" autocomplete="phone" />
      </mat-form-field>
    </div>
    <div class="flex flex-row gap-2 justify-end pt-6">
      <button type="button" class="btn-secondary" [mat-dialog-close] i18n>Avbryt</button>
      <button type="submit" class="btn" cdkFocusInitial [disabled]="form.disabled">
        <span *ngIf="!isNil(data.id)" i18n>Spara</span>
        <span *ngIf="isNil(data.id)" i18n>Lägg till</span>
      </button>
    </div>
  </div>
</form>
