import { DateTime } from 'luxon';
import { isString } from 'lodash';
import lodash from 'lodash';

export function toDateTime(date: DateTime | Date | string): DateTime {
  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  } else if (isString(date)) {
    return DateTime.fromISO(date);
  }
  return date;
}

export function toDateTimeMaybe(date: DateTime | Date | string | null | undefined): DateTime | null | undefined {
  if (lodash.isNil(date)) {
    return date;
  }
  return toDateTime(date);
}

export function toDate(date: DateTime | Date): Date {
  if (date instanceof DateTime) {
    return date.toJSDate();
  }
  return date;
}

export function toDateMaybe(date: DateTime | Date | null | undefined): Date | null | undefined {
  if (lodash.isNil(date)) {
    return date;
  }
  if (date instanceof DateTime) {
    return date.toJSDate();
  }
  return date;
}
