import { ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { VestingFrequency, VestingKind } from '@startuptools/common/stock-options';
import { LoadingDirective } from '@startuptools/angular/loading';
import { PaymentKind } from '@startuptools/common/participants';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  concatMap,
  first,
  map,
  shareReplay,
  startWith,
  filter,
} from 'rxjs';
import { isNil, first as _first, toNumber } from 'lodash-es';
import { SubSink } from 'subsink';
import { BigNumber } from 'bignumber.js';
import { AppState } from '../../store/reducers';
import { WarrantProgramParticipantId } from '../../models/warrant-program-participant.model';
import { compareShareClasses } from '../../helpers/compare-share-classes';
import { getDurationSelectOptions } from '../../helpers/wpp-duration';
import { selectCompany } from '../../store/selectors/companies.base';
import { selectWarrantProgram } from '../../store/selectors/warrant-programs.base';
import { selectWarrantProgramParticipantById } from '../../store/selectors/warrant-participants.base';
import { paymentKindLabel } from '../../pipes/payment-kind-human.pipe';
import { GqlCreateIdentityInput, GqlWarrantProgramKind, GqlWpOptionsLeftService } from '../../graphql/operations';
import { claimRecalc } from '../../common/claim-recalc';
import { GqlShareClass } from '../../graphql/base-types.graphql';

interface Response {
  id: WarrantProgramParticipantId | null;
  quantity: BigNumber;
  shareClass: GqlShareClass;
  email: string;
  phone: string | null;
  forcedRedemption: boolean | null;
  vestingKind: number | null;
  vestingDuration: number;
  vestingFrequency: VestingFrequency;
  vestingCliffDuration: number;
  vestingAcceleration: boolean;
  repurchaseVested: boolean | null;
  paymentKind: PaymentKind;
  claim: BigNumber | null;
}

interface Data {
  id?: WarrantProgramParticipantId;
  warrantKind: GqlWarrantProgramKind;
  identity: GqlCreateIdentityInput;
  shareClasses: GqlShareClass[];
  paymentKind?: PaymentKind;
  disableEditQuantity?: boolean;
  onSubmit: (response: Response) => Observable<unknown>;
}

@Component({
  templateUrl: './options-subscriber-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsSubscriberDialogComponent implements OnDestroy {
  @ViewChild('qLoading') qLoading: LoadingDirective;
  compareShareClasses = compareShareClasses;
  paymentKindLabel = paymentKindLabel;
  PaymentKind = PaymentKind;
  isNil = isNil;
  VestingFrequency = VestingFrequency;
  VestingKind = VestingKind;
  GqlWarrantProgramKind = GqlWarrantProgramKind;
  subs = new SubSink();

  formControls = {
    id: new FormControl<string | null>(null),
    quantity: new FormControl<BigNumber | null>(null),
    shareClass: new FormControl<GqlShareClass | null>(null),
    email: new FormControl<string | null>(null),
    phone: new FormControl<string | null>(null),
    forcedRedemption: new FormControl<boolean>(true),
    vestingKind: new FormControl<VestingKind>(VestingKind.None),
    vestingDuration: new FormControl<number | null>(null),
    vestingFrequency: new FormControl<VestingFrequency | null>(null),
    vestingCliffDuration: new FormControl<number | null>(null),
    vestingAcceleration: new FormControl<boolean | null>(null),
    repurchaseVested: new FormControl<boolean | null>(null),
    paymentKind: new FormControl<PaymentKind | null>(null),
    claim: new FormControl<BigNumber | null>(null),
  } satisfies { [key in keyof Response]: FormControl };
  form = new FormGroup(this.formControls);

  // @ts-expect-error TS2345
  selectedShareClassSub = new BehaviorSubject<ShareClass>(null);
  selectedShareClass$ = this.selectedShareClassSub.asObservable().pipe(filter(e => !isNil(e)));

  // @ts-expect-error TS2345
  selectablePaymentsSub = new BehaviorSubject<PaymentKind[]>(null);
  selectablePayments$ = this.selectablePaymentsSub.asObservable();

  // @ts-expect-error TS2345
  selectedPaymentSub = new BehaviorSubject<PaymentKind>(null);
  selectedPayment$ = this.selectedPaymentSub.asObservable();

  company$ = this.store.pipe(
    select(selectCompany),
    filter(m => !isNil(m)),
  );
  wp$ = this.store.pipe(
    select(selectWarrantProgram),
    filter(wp => !isNil(wp)),
  );
  // @ts-expect-error TS2345
  wpp$ = this.store.pipe(select(selectWarrantProgramParticipantById(this.data.id)));

  durationSelectOptions$ = combineLatest([
    this.wp$,
    this.formControls.vestingFrequency.valueChanges.pipe(startWith(this.formControls.vestingFrequency.value)),
  ]).pipe(
    map(([wp, vestingFrequency]) => {
      return getDurationSelectOptions(
        wp.warrantStartDate,
        wp.warrantEndDate,
        // @ts-expect-error TS2345
        vestingFrequency,
        wp.warrantKind === GqlWarrantProgramKind.Employee ? 36 : 0,
      );
    }),
    shareReplay(0),
  );

  shareClasses$ = of(this.data.shareClasses);

  paymentKind$ = of(this.data.paymentKind).pipe(
    map(pk => {
      if (isNil(pk)) {
        return PaymentKind.Cash;
      }
      return pk;
    }),
  );

  sharesLeftToDistribute$ = combineLatest([this.selectedShareClass$, this.wpp$, this.wp$]).pipe(
    concatMap(([sc, wpp, wp]) =>
      this.gqlWpOptionsLeft
        .fetch(
          {
            companyId: wp.companyId,
            input: { id: wp.id, shareClassName: sc?.name },
          },
          { fetchPolicy: 'no-cache' },
        )
        .pipe(
          map(res => res.data.wpOptionsLeft),
          map(optionsLeft => {
            if (!isNil(wpp) && wpp.shareClass.name === sc.name) {
              return optionsLeft + toNumber(wpp.quantity);
            }
            return optionsLeft;
          }),
        ),
    ),
  );

  showShareClasses$ = this.wp$.pipe(
    map(wp => {
      if ([GqlWarrantProgramKind.Wise, GqlWarrantProgramKind.Nice].includes(wp.warrantKind)) {
        return false;
      }
      return !wp.usesStepper;
    }),
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private dialogRef: MatDialogRef<OptionsSubscriberDialogComponent>,
    private store: Store<AppState>,
    private gqlWpOptionsLeft: GqlWpOptionsLeftService,
  ) {
    // @ts-expect-error TS2345
    this.formControls.email.setValue(data.identity.email);
    // @ts-expect-error TS2345
    this.formControls.phone.setValue(data.identity.phone);

    if (!isNil(data.id)) {
      this.wpp$.pipe(first()).subscribe(wpp => {
        const fc = this.formControls;
        // @ts-expect-error TS18048
        fc.id.setValue(wpp.id);
        // @ts-expect-error TS18048
        fc.quantity.setValue(!isNil(wpp.quantity) ? new BigNumber(wpp.quantity) : null);
        // @ts-expect-error TS18048
        fc.forcedRedemption.setValue(wpp.forcedRedemption);
        // @ts-expect-error TS18048
        fc.vestingKind.setValue(wpp.vestingKind);
        // @ts-expect-error TS18048
        fc.vestingDuration.setValue(wpp.vestingDuration);
        // @ts-expect-error TS18048
        fc.vestingFrequency.setValue(wpp.vestingFrequency);
        // @ts-expect-error TS18048
        fc.vestingCliffDuration.setValue(wpp.vestingCliffDuration);
        // @ts-expect-error TS18048
        fc.vestingAcceleration.setValue(wpp.vestingAcceleration);
        // @ts-expect-error TS18048
        fc.repurchaseVested.setValue(wpp.repurchaseVested);
        // @ts-expect-error TS18048
        fc.paymentKind.setValue(wpp.paymentKind);
        fc.claim.setValue(!isNil(wpp?.claim) ? new BigNumber(wpp.claim) : null);
      });
    } else {
      this.paymentKind$.pipe(first()).subscribe(pk => {
        this.formControls.paymentKind.setValue(pk);
      });
    }

    this.subs.sink = this.formControls.shareClass.valueChanges.subscribe(sc => this.selectedShareClassSub.next(sc));

    combineLatest([this.shareClasses$, this.wpp$])
      .pipe(first())
      .subscribe(([shareClasses, wpp]) => {
        if (shareClasses.length === 0) {
          this.formControls.shareClass.setValue({
            name: 'STAM',
            voteWeight: '1',
          });
        } else if (isNil(wpp?.shareClass) || !shareClasses.some(sc => sc.name === wpp.shareClass.name)) {
          const stam = shareClasses.find(sc => sc.name === 'STAM');
          // @ts-expect-error TS2345
          this.formControls.shareClass.setValue(stam ?? _first(shareClasses));
        } else {
          this.formControls.shareClass.setValue(wpp.shareClass);
        }
      });

    this.subs.sink = this.formControls.vestingDuration.valueChanges.subscribe(d => {
      const cliff = this.formControls.vestingCliffDuration.value;
      if (isNil(d)) {
        return;
      }
      // @ts-expect-error TS18047
      if (cliff > d) {
        this.formControls.vestingCliffDuration.setValue(d);
      }
    });
  }

  static open(dialog: MatDialog, data: Data): MatDialogRef<OptionsSubscriberDialogComponent> {
    return dialog.open<OptionsSubscriberDialogComponent, Data>(OptionsSubscriberDialogComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
      data,
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  calcNumberOfOptionsByClaim() {
    combineLatest([this.sharesLeftToDistribute$, this.wp$])
      .pipe(first())
      .subscribe(([sharesLeft, wp]) => {
        const ppw = new BigNumber(wp.pricePerWarrant);
        // @ts-expect-error TS2345
        const { newClaim, units } = claimRecalc(this.formControls.claim.value, ppw, sharesLeft);
        this.formControls.quantity.setValue(units);
        this.formControls.claim.setValue(newClaim);
      });
  }

  calcClaimByNumberOfOptions() {
    this.wp$.pipe(first()).subscribe(wp => {
      const claim = new BigNumber(wp.pricePerWarrant).times(this.formControls.quantity.value || '0');
      this.formControls.claim.setValue(new BigNumber(claim));
    });
  }

  allowedToSubscribeByClaim(optionsLeft: number, pricePerWarrant: string, claim: string): number {
    const c = toNumber(claim);
    const ppw = toNumber(pricePerWarrant);

    let numberOfOptions = 0;
    let totalPrice = 0;

    for (let i = 0; i < optionsLeft; i++) {
      const totPrice = totalPrice + ppw;
      if (totPrice <= c) {
        totalPrice = totPrice;
        numberOfOptions++;
      } else {
        break;
      }
    }
    return numberOfOptions;
  }

  takeAll() {
    this.sharesLeftToDistribute$.pipe(first()).subscribe(sharesLeft => {
      this.formControls.quantity.setValue(new BigNumber(sharesLeft.toFixed(0)));
      this.form.markAsDirty();
    });
  }

  submit() {
    if (this.form.invalid) {
      this.form.enable();
      return;
    }
    const response = this.form.dirty ? (this.form.value as Response) : null;
    this.qLoading.start();
    this.data
      // @ts-expect-error TS2345
      .onSubmit(response)
      .pipe(first())
      .subscribe({
        next: () => this.dialogRef.close(),
        error: () => this.form.enable(),
        complete: () => this.qLoading.stop(),
      });
  }
}
