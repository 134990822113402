import { Trans, useLingui } from '@lingui/react';
import { useDebounce } from '@startuptools/common/react-hooks';
import {
  Button,
  Card,
  CircularProgress,
  CloseIcon,
  DateFormat,
  ErrorBoundary,
  ExpandLessIcon,
  ExpandMoreIcon,
  IconButton,
  Input,
  SearchIcon,
  Sheet,
  Stack,
  Table,
} from '@startuptools/ui';
import { useForm } from 'react-hook-form';
import { GqlAdminShareholdersQuery, useAdminShareholdersSuspenseQuery } from '../../graphql/react-operations';
import { ArrayElement } from '@startuptools/common/common';
import { DateTime } from 'luxon';
import { Suspense, useState } from 'react';
import { groupBy } from 'lodash-es';
import { Identity } from '../../components/identity/Identity';
import { IdentityDisplay } from '../../components/identity/IdentityDisplay';
import { useNgRouter } from '../../components/react-wrapper/AngularRouterContext';

const ShareholdersTableRow = ({
  shareholder: sh,
  companies,
  onClose,
}: {
  shareholder: ArrayElement<GqlAdminShareholdersQuery['adminShareholders']>;
  companies: ArrayElement<GqlAdminShareholdersQuery['adminShareholders']>['company'][];
  onClose: () => void;
}) => {
  const { i18n } = useLingui();
  const { changeCompany } = useNgRouter();
  const [open, setOpen] = useState(false);

  const handleNavigate = (companyId: string) => {
    void changeCompany(companyId);
    onClose();
  };

  return (
    <>
      <tr>
        <td>
          <IconButton variant="outlined" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </td>
        <td>{sh.identity && <Identity identity={sh.identity} />}</td>
        <td>{sh.identity?.email}</td>
        <td>
          <DateFormat locale={i18n.locale} format={DateTime.DATETIME_SHORT}>
            {sh.createdAt}
          </DateFormat>
        </td>
      </tr>
      {open && (
        <tr>
          <td colSpan={7}>
            <Sheet variant="soft">
              <Table sx={{ tableLayout: 'auto' }}>
                <thead>
                  <tr>
                    <th>
                      <Trans id="Company" />
                    </th>
                    <th>
                      <Trans id="Created At" />
                    </th>
                    <th>HubSpot ID</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {companies.map(company => (
                    <tr key={company.id}>
                      <td>
                        <IdentityDisplay name={company.name} secondRow={company.organizationNumber} />
                      </td>
                      <td>
                        <DateFormat locale={i18n.locale} format={DateTime.DATETIME_SHORT}>
                          {company.createdAt}
                        </DateFormat>
                      </td>
                      <td align="right">{company.hubspotObjectId}</td>
                      <td align="right">
                        <Button variant="outlined" color="neutral" size="sm" onClick={() => handleNavigate(company.id)}>
                          <Trans id="Visit" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Sheet>
          </td>
        </tr>
      )}
    </>
  );
};

const ShareholdersTable = ({ searchString, onClose }: { searchString: string; onClose: () => void }) => {
  const {
    data: { adminShareholders: shareholders },
  } = useAdminShareholdersSuspenseQuery({
    variables: { input: { filter: searchString, pageOffset: 0, pageLimit: 30 } },
    fetchPolicy: 'cache-and-network',
  });

  const groupedShareholders = groupBy(shareholders, sh => sh.identity?.identityNumber);

  return (
    <Card sx={{ width: '100%' }}>
      <Table sx={{ tableLayout: 'auto', width: '100%' }}>
        <thead>
          <tr>
            <th />
            <th>
              <Trans id="User" />
            </th>
            <th>
              <Trans id="Email" />
            </th>
            <th>
              <Trans id="Created At" />
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.values(groupedShareholders).map(shareholders => (
            <ShareholdersTableRow
              // @ts-expect-error TS2532
              key={shareholders[0].id}
              // @ts-expect-error TS2322
              shareholder={shareholders[0]}
              companies={shareholders.map(sh => sh.company)}
              onClose={onClose}
            />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const Shareholders = ({ onClose }: { onClose: () => void }) => {
  const { i18n } = useLingui();
  const { register, watch, setValue } = useForm<{ searchString: string }>({ defaultValues: { searchString: '' } });
  const searchString = watch('searchString');
  const debouncedSearchString = useDebounce(searchString);
  return (
    <Stack gap={2} alignItems="center">
      <Input
        fullWidth
        {...register('searchString')}
        startDecorator={searchString === debouncedSearchString ? <SearchIcon /> : <CircularProgress size="sm" />}
        endDecorator={
          searchString?.length ? (
            <IconButton onClick={() => setValue('searchString', '')}>
              <CloseIcon />
            </IconButton>
          ) : undefined
        }
        type="search"
        placeholder={i18n._('Search...')}
      />
      <ErrorBoundary>
        <Suspense>
          <ShareholdersTable searchString={debouncedSearchString} onClose={onClose} />
        </Suspense>
      </ErrorBoundary>
    </Stack>
  );
};
