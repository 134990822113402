<div class="dialog">
  <div class="dialog-close gap-2">
    <button mat-icon-button [mat-dialog-close]="null">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content class="dialog-content mt-2 w-full">
    <app-react-wrapper [component]="DocumentDialogContent" [props]="{ documentId }" [companyId]="companyId$ | async" />
  </div>
</div>
