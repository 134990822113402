import { Transform } from 'class-transformer';
import { DateTime } from 'luxon';
import { ScriveParty } from '@startuptools/common/scrive';
import { jsonParse } from './utils';
import {
  GqlDocGenState,
  GqlDocSystemTagKind,
  GqlDocument,
  GqlScriveDocumentStatus,
  GqlZignedAgreementStatus,
} from '../graphql/operations';
import { GqlDocumentSignatureMethod, GqlSignAllowedAction } from '../graphql/base-types.graphql';

export type DocumentId = string;

export class Document implements Omit<GqlDocument, 'documentSignatures' | 'adminCompany'> {
  id: DocumentId;

  companyId: string;
  mimeType: string;
  filename: string;
  filesize: number;
  sha256: string;
  signedFilesize?: number;
  signedSha256?: string;

  signAllowedAction: GqlSignAllowedAction;

  zignedAgreementStatus?: GqlZignedAgreementStatus | null;

  scriveStatus: GqlScriveDocumentStatus;
  @Transform(({ value }: { value: string | null | undefined }) => (value ? DateTime.fromISO(value) : null), {
    toClassOnly: true,
  })
  scriveMtime: DateTime | null | undefined;
  @Transform(({ value }: { value: string | null | undefined }) => (value ? DateTime.fromISO(value) : null), {
    toClassOnly: true,
  })
  scriveCtime: DateTime | null | undefined;
  @Transform(({ value }: { value: string }) => jsonParse(value), {
    toClassOnly: true,
  })
  scriveParties: ScriveParty[];

  hasSignStatus: boolean;
  hasThumbnail: boolean;

  signatureMethod: GqlDocumentSignatureMethod;
  dirty: boolean;

  warrantParticipantId: string | undefined;
  optionSharesSubscriberId: string | undefined;

  kind: GqlDocSystemTagKind;

  groupId: string;

  userTags?: string[];

  uploaded: boolean;
  deletable: boolean;
  isFullySigned: boolean;

  genState: GqlDocGenState;

  @Transform(({ value }: { value: string }) => DateTime.fromISO(value), {
    toClassOnly: true,
  })
  readonly createdAt: DateTime;

  @Transform(({ value }: { value: string }) => DateTime.fromISO(value), {
    toClassOnly: true,
  })
  readonly updatedAt: DateTime;

  constructor(seed?: Partial<Document>) {
    Object.assign(this, seed);
  }
}
