import { useCompanyId } from '@startuptools/common/react-wrapper';
import { IdentityDisplay, IdentityDisplaySkeleton } from '../identity/IdentityDisplay';
import { useCompaniesSuspenseQuery, useCompanySuspenseQuery } from '../../graphql/react-operations';
import {
  Avatar,
  Button,
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
  UnfoldMoreIcon,
  MenuIcon,
} from '@startuptools/ui';
import { ComponentProps, Suspense, useState } from 'react';
import { Trans } from '@lingui/react';
import { CreateCompanyDialog } from '../create-company/CreateCompanyDialog';
import { toggleSidebar } from './utils';

import { useNgRouter } from '../react-wrapper/AngularRouterContext';

const CompanySelectInner = ({ companyId }: { companyId: string }) => {
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const { changeCompany } = useNgRouter();
  const {
    data: { companies },
  } = useCompaniesSuspenseQuery({ fetchPolicy: 'cache-and-network' });
  const {
    data: { company },
  } = useCompanySuspenseQuery({ variables: { id: companyId } });

  return (
    <>
      <Stack direction="row" gap={2}>
        <IconButton
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
          onClick={() => toggleSidebar()}
          variant="outlined"
          color="neutral"
          size="sm"
        >
          <MenuIcon />
        </IconButton>
        <IdentityDisplay
          name={company.name}
          secondRow={company.organizationNumber}
          startDecorator={
            <Avatar variant="solid" color="primary">
              <Typography level="title-lg" sx={{ color: 'white' }}>
                {company.name?.[0]}
              </Typography>
            </Avatar>
          }
        />
        <Dropdown>
          <MenuButton slots={{ root: IconButton }}>
            <UnfoldMoreIcon />
          </MenuButton>
          <Menu sx={{ maxHeight: '90%' }}>
            <MenuItem sx={{ paddingX: 2 }}>
              <Button fullWidth variant="outlined" onClick={() => setShowCreateDialog(true)}>
                <Trans id="Setup New Company" />
              </Button>
            </MenuItem>
            {companies.map(c => (
              <MenuItem key={c.id} sx={{ paddingY: 1, paddingX: 2 }} onClick={() => changeCompany(c.id)}>
                <IdentityDisplay
                  name={c.name}
                  secondRow={c.organizationNumber}
                  startDecorator={
                    <Avatar variant="soft" color="neutral" size="sm">
                      <Typography level="title-md">{c.name?.[0]}</Typography>
                    </Avatar>
                  }
                />
              </MenuItem>
            ))}
          </Menu>
        </Dropdown>
      </Stack>
      {showCreateDialog && <CreateCompanyDialog onClose={() => setShowCreateDialog(false)} />}
    </>
  );
};

const CompanySelectInnerSkeleton = () => {
  return <IdentityDisplaySkeleton layout="column" />;
};

export const CompanySelect = (props: Omit<ComponentProps<typeof CompanySelectInner>, 'companyId'>) => {
  const companyId = useCompanyId();

  return (
    <Suspense fallback={<CompanySelectInnerSkeleton />}>
      <CompanySelectInner companyId={companyId} {...props} />
    </Suspense>
  );
};
