import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { keys, find, first, isNil, orderBy } from 'lodash-es';
import { SubSink } from 'subsink';
import { Company } from '../../models/company.model';
import { GqlShareClassesService, GqlShareClass } from '../../graphql/operations';

interface Data {
  company: Company;
  shareClass?: GqlShareClass;
  saveFunction: (shareClass: GqlShareClass) => Observable<unknown>;
}

@Component({
  selector: 'app-select-share-class-dialog',
  templateUrl: './select-share-class-dialog.component.html',
})
export class SelectShareClassDialogComponent implements OnDestroy {
  keys = keys;

  subs = new SubSink();
  formControls = {
    shareClassName: new UntypedFormControl(),
    shareClassVoteWeight: new UntypedFormControl(),
  };
  formGroup = new UntypedFormGroup(this.formControls);
  selectedShareClass: GqlShareClass;

  shareClasses$ = this.gqlShareClasses.fetch({ companyId: this.data.company.id }, { fetchPolicy: 'no-cache' }).pipe(
    map(res => res.data.shareClasses),
    map(shareClasses => orderBy(shareClasses, sc => sc.name)),
    tap(shareClasses => {
      if (shareClasses.length > 0) {
        const def = find(shareClasses, sc => sc.name === (this.data.shareClass?.name ?? 'STAM'));
        // @ts-expect-error TS2322
        this.selectedShareClass = def || first(shareClasses);
      }
    }),
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private dialogRef: MatDialogRef<SelectShareClassDialogComponent>,
    private gqlShareClasses: GqlShareClassesService,
  ) {}

  static open(dialog: MatDialog, data: Data) {
    return dialog.open<SelectShareClassDialogComponent, Data, GqlShareClass>(SelectShareClassDialogComponent, {
      width: '600px',
      disableClose: true,
      data,
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  submit() {
    let shareClass: GqlShareClass;
    if (keys(this.selectedShareClass).length > 0) {
      shareClass = this.selectedShareClass;
    } else {
      shareClass = {
        name: this.formControls.shareClassName.value,
        voteWeight: `${this.formControls.shareClassVoteWeight.value}`,
      };
    }
    if (isNil(shareClass.name) || isNil(shareClass.voteWeight)) {
      return;
    }
    this.subs.sink = this.data.saveFunction(shareClass).subscribe({
      next: () => {
        this.dialogRef.close();
      },
      error: err => console.error(err),
    });
  }

  changed() {
    this.formGroup.reset();
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
    if (keys(this.selectedShareClass).length === 0) {
      this.formControls.shareClassName = new UntypedFormControl(null, Validators.required);
      this.formControls.shareClassVoteWeight = new UntypedFormControl(null, Validators.required);
    } else {
      this.formControls.shareClassName = new UntypedFormControl();
      this.formControls.shareClassVoteWeight = new UntypedFormControl();
    }
  }
}
