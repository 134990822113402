<form [formGroup]="form" (submit)="submit()">
  <div class="dialog" sutLoading #qLoading="Loading">
    <div class="dialog-title">
      <app-identity-wrapper [identity]="data.identity" [editable]="false" [showAvatar]="true" />
    </div>

    <div mat-dialog-content class="dialog-content">
      <div>
        <mat-form-field *ngIf="data.settings.fields?.shareClass" class="w-full" appearance="fill">
          <mat-label i18n>Aktieslag</mat-label>
          <mat-select [formControl]="formControls.shareClass" [compareWith]="compareShareClasses" required>
            <mat-option *ngFor="let sc of shareClasses$ | async" [value]="sc">
              {{ sc.name }} <small>(röstvikt: {{ sc.voteWeight }})</small>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formControls.shareClass.hasError('required')" i18n>Måste fyllas i</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field *ngIf="data.settings.fields.quantity" class="w-full" appearance="fill">
          <mat-label i18n>Antal aktier</mat-label>
          <input
            matInput
            type="text"
            [formControl]="formControls.quantity"
            localizedNumericInput
            [numericIntegerOnly]="true"
            autocomplete="off"
            required
            [minNumber]="1"
          />
          <span matSuffix *ngIf="data?.limits"
            >/ <span (click)="takeAll()" clickable>{{ getLimit() | number: '1.0' }}</span></span
          >
          <mat-error *ngIf="formControls.quantity.hasError('minNumber')" i18n>Ange minst 1</mat-error>
          <mat-error *ngIf="formControls.quantity.hasError('required')" i18n>Måste fyllas i</mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field *ngIf="data.settings.fields?.claim" class="w-full" appearance="fill">
          <mat-label i18n>Totalbelopp</mat-label>
          <input
            matInput
            type="text"
            [formControl]="formControls.claim"
            localizedNumericInput
            autocomplete="off"
            [minNumber]="minClaim$ | async"
            required
            [readonly]="true"
          />
          <span matSuffix>{{ data.currency }}</span>
          <mat-hint *ngIf="data.paymentKind === PaymentKind.Offset" i18n
            >Fylls i automatiskt, ange antal aktier ovan</mat-hint
          >
          <mat-error *ngIf="formControls.claim.hasError('minNumber')" i18n
            >Minsta belopp är {{ minClaim$ | async }} {{ data.currency }}</mat-error
          >
          <mat-error *ngIf="formControls.claim.hasError('required')" i18n>Måste fyllas i</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="flex flex-row gap-2 justify-end pt-6">
      <button type="button" class="btn-secondary" [mat-dialog-close] i18n>Avbryt</button>
      <button type="submit" class="btn" cdkFocusInitial [disabled]="form.disabled" i18n>Spara</button>
    </div>
  </div>
</form>
