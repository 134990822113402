import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { isNil, forEach, omit, isArray, values, has, isObject, get, find } from 'lodash-es';
import { plainToInstance } from 'class-transformer';
import { warrantProgramsActions } from '../../store/actions/warrant-programs.actions';
import { AppState } from '../reducers';
import { WarrantProgram } from '../../models/warrant-program.model';
import { shareIssueActions } from '../../store/actions/share-issues.actions';
import { documentsActions } from '../actions/documents.actions';
import { Document } from '../../models/document.model';
import { ShareIssue } from '../../models/share-issue.model';
import { identitiesActions } from '../actions/identities.actions';
import { Identity } from '../../models/identity.model';
import { meetingActions } from '../actions/meetings.actions';
import { Meeting } from '../../models/meeting.model';
import { agendaItemsActions } from '../actions/agenda-items.actions';
import { AgendaItem } from '../../models/agenda-item.model';
import { agendaItemDecisionsActions } from '../actions/agenda-item-decisions.actions';
import { AgendaItemDecision } from '../../models/agenda-item-decision.model';
import { MeetingConcern } from '../../models/meeting-concern.model';
import { meetingConcernsActions } from '../actions/meeting-concerns.actions';
import { WarrantProgramParticipant } from '../../models/warrant-program-participant.model';
import { warrantParticipantsActions } from '../actions/warrant-participants.actions';
import { MeetingAgendaTemplate } from '../../models/meeting-agenda-template.model';
import { meetingAgendaTemplatesActions } from '../actions/meeting-agenda-templates.actions';
import { WarrantShareholder } from '../../models/warrant-shareholder.model';
import { warrantShareholdersActions } from '../actions/warrant-shareholders.actions';
import { Shareholder } from '../../models/shareholder.model';
import { shareholdersActions } from '../actions/shareholders.actions';
import { warrantProgramDocumentsActions } from '../actions/warrant-program-documents.actions';
import { WarrantProgramDocument } from '../../models/warrant-program-document.model';
import { meetingDocumentsActions } from '../actions/meeting-documents.actions';
import { MeetingDocument } from '../../models/meeting-document.model';
import { MeetingParticipant } from '../../models/meeting-participant.model';
import { meetingParticipantsActions } from '../actions/meeting-participants.actions';
import { ArticlesOfAssoc } from '../../models/articles-of-assoc.model';
import { articlesOfAssocsActions } from '../actions/articles-of-associations.actions';
import { authorizationGroupActions } from '../actions/authorization-groups.actions';
import { AuthorizationGroup } from '../../models/authorization-group.model';
import { shareIssueBoardMembersActions } from '../actions/share-issue-board-members.actions';
import { ShareIssueBoardMember } from '../../models/share-issue-board-member.model';
import { ShareIssueParticipant } from '../../models/share-issue-participant.model';
import { shareIssueParticipantsActions } from '../actions/share-issue-participants.actions';
import { shareIssueDocumentsActions } from '../actions/share-issue-documents.actions';
import { ShareIssueDocument } from '../../models/share-issue-document.model';
import { warrantBoardMembersActions } from '../actions/warrant-board-member.actions';
import { WarrantBoardMember } from '../../models/warrant-board-member.model';
import { authorizationsActions } from '../actions/authorizations.actions';
import { Authorization } from '../../models/authorization.model';
import { snrAuthorizationsActions } from '../actions/snr-authorizations.actions';
import { SnrAuthorization } from '../../models/snr-authorization.model';
import { MeetingAgendaTemplateItem } from '../../models/meeting-agenda-template-item.model';
import { meetingAgendaTemplateItemsActions } from '../actions/meeting-agenda-template-items.actions';
import { companiesActions } from '../actions/companies.actions';
import { Company } from '../../models/company.model';
import { SnrCase } from '../../models/snr-case.model';
import { snrCasesActions } from '../actions/snr-cases.actions';
import { usersActions } from '../../store/actions/users.actions';
import { User } from '../../models/user.model';
import { AuthorizationGroupDocument } from '../../models/authorization-group-document.model';
import { authorizationGroupDocumentsActions } from '../../store/actions/authorization-groups-documents.actions';
import { CompanyDirector } from '../../models/company-director.model';
import { ArticlesOfAssocDocument } from '../../models/articles-of-assoc-document.model';
import { articlesOfAssocDocumentsActions } from '../../store/actions/articles-of-assoc-documents.actions';

import {
  GqlMcDeletedResponse,
  GqlWppDeletedResponse,
  GqlAidDeletedResponse,
  GqlAiDeletedResponse,
  GqlMeetingDeletedResponse,
  GqlWshDeletedResponse,
  GqlWpDeletedResponse,
  GqlSipDeletedResponse,
  GqlSibmDeletedResponse,
  GqlWpbmDeletedResponse,
  GqlSiDeletedResponse,
  GqlAoaDeletedResponse,
  GqlAgDeletedResponse,
  GqlAuthDeletedResponse,
  GqlMpDeletedResponse,
  GqlMatiDeletedResponse,
  GqlMatDeletedResponse,
  GqlDocDeletedResponse,
  GqlMpDeletedShareholdersResponse,
  GqlShDeletedResponse,
  GqlOptionsRegistration,
  GqlOrDeletedResponse,
  GqlOptionsRegistrationSignatory,
  GqlOrSignatoryDeletedResponse,
  GqlOptionsSharesSubscriber,
  GqlOssDeletedResponse,
  GqlOptionsSubscriberRepresentative,
  GqlOptionsSubscriberRepresentativeDeletedResponse,
  GqlOptionsSharesSubscriberRepresentative,
  GqlNiceFounder,
  GqlNiceFounderDeletedResponse,
  GqlScriveDocumentStatus,
} from '../../graphql/operations';

import { snrShareCapitalChangesActions } from '../../store/actions/snr-share-capital-changes.actions';
import { SnrShareCapitalChange } from '../../models/snr-share-capital-change.model';
import { companyDirectorsActions } from '../../store/actions/company-directors.actions';
import { snrBondsActions } from '../../store/actions/snr-bonds.actions';
import { SnrBond } from '../../models/snr-bond.model';
import { optionsRegistrationActions } from '../actions/options-registrations.actions';
import { OptionsRegistration } from '../../models/options-registration.model';
import { graphqlActions } from '../../store/actions/graphql.actions';
import { OptionsRegistrationSignatory } from '../../models/options-registration-signatory.model';
import { optionsRegistrationSignatoryActions } from '../actions/options-registration-signatory.actions';
import { wiseDependencyBondsActions } from '../actions/wise-dependency-bond.actions';
import {
  GqlOptionsSharesRegistration,
  GqlOsrDeletedResponse,
  GqlWdbDeletedResponse,
  GqlWdbResetResponse,
  GqlWiseDependencyBond,
} from '../../graphql/operations';
import { optionsSharesRegistrationActions } from '../actions/options-shares-registrations.actions';
import { OptionsSharesRegistration } from '../../models/options-shares-registration.model';
import { OptionsSharesSubscriber } from '../../models/options-shares-subscriber.model';
import { optionsSharesSubscribersActions } from '../actions/options-shares-subscriber.actions';
import { optionsSubscriberRepresentativeActions } from '../actions/options-subscriber-representative.actions';
import { optionsSharesSubscriberRepresentativeActions } from '../actions/options-shares-subscriber-representative.actions';
import { OptionsSubscriberRepresentative } from '../../models/options-subscriber-representatives.model';
import { OptionsSharesSubscriberRepresentative } from '../../models/options-shares-subscriber-representatives.model';
import { GraphQLError } from 'graphql';
import { niceFoundersActions } from '../actions/nice-founders.actions';
import { NiceFounder } from '../../models/nice-founder.model';
import { shareIssueRegistrationActions } from '../actions/share-issue-registrations.actions';
import { ShareIssueRegistration } from '../../models/share-issue-registration.model';
import { GqlWarrantProgram } from '../../graphql/react-operations';

interface Data extends Record<string, unknown> {
  __typename: string;
}

enum QoorpErrorPropertyKey {
  Delete = 'delete',
}

const scriveStatusErrorMessage = {
  [GqlScriveDocumentStatus.Closed]:
    'Mötet är signerat och klart så det går inte att ta bort. Kontakta support om du ändå vill ta bort det.',
  [GqlScriveDocumentStatus.Pending]: 'Mötet har en aktiv signeringsprocess, stoppa den för att kunna ta bort mötet',
};

@Injectable()
export class GraphqlEffects {
  query2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(graphqlActions.query),
      concatMap(action => {
        return action.payload.query().pipe(
          map(result =>
            graphqlActions.result({
              payload: { opId: action.payload.opId, result },
            }),
          ),
          catchError(error =>
            of(
              graphqlActions.error({
                payload: { opId: action.payload.opId, error },
              }),
            ),
          ),
        );
      }),
    ),
  );

  mutate2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(graphqlActions.mutate),
      concatMap(action => {
        return action.payload.mutation().pipe(
          map(result =>
            graphqlActions.result({
              payload: { opId: action.payload.opId, result },
            }),
          ),
          catchError(error =>
            of(
              graphqlActions.error({
                payload: { opId: action.payload.opId, error },
              }),
            ),
          ),
        );
      }),
    ),
  );

  result$ = createEffect(() =>
    this.actions$.pipe(
      ofType(graphqlActions.result),
      map(action => {
        // if (!isNil(action?.payload?.result?.data)) {
        this.storeQueryResult(action.payload.result.data);
        return graphqlActions.resultStored(action);
        // }
      }),
    ),
  );

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(graphqlActions.error),
      concatMap(action => {
        const foundQoorpError = find(
          action.payload?.error?.graphQLErrors,
          e => e.extensions?.code === 'QOORP_ERROR',
        ) as GraphQLError;
        if (!isNil(foundQoorpError)) {
          if (foundQoorpError.extensions.propertyKey === QoorpErrorPropertyKey.Delete) {
            // @ts-expect-error TS7053
            this.snackBar.open(scriveStatusErrorMessage[foundQoorpError.extensions.scriveStatus as string], 'OK', {
              duration: 10000,
            });
          } else {
            this.snackBar.open(foundQoorpError.message, 'OK', {
              duration: 10000,
            });
          }
        }
        return of(graphqlActions.resultStored(action));
      }),
    ),
  );

  private actions = {
    Company: (payload: unknown[]) => companiesActions.manyLoaded({ payload: plainToInstance(Company, payload) }),

    WarrantProgram: (payload: GqlWarrantProgram[]) =>
      warrantProgramsActions.manyLoaded({
        payload: plainToInstance(WarrantProgram, payload),
      }),
    WpDeletedResponse: (payload: GqlWpDeletedResponse[]) =>
      warrantProgramsActions.manyDeleted({ payload: payload.map(e => e.id) }),

    Document: (payload: unknown[]) => documentsActions.manyLoaded({ payload: plainToInstance(Document, payload) }),
    DocDeletedResponse: (payload: GqlDocDeletedResponse[]) =>
      documentsActions.manyDeleted({ payload: payload.map(e => e.id) }),

    ShareIssue: (payload: unknown[]) =>
      shareIssueActions.manyLoaded({
        payload: plainToInstance(ShareIssue, payload),
      }),
    SiDeletedResponse: (payload: GqlSiDeletedResponse[]) =>
      shareIssueActions.manyDeleted({ payload: payload.map(e => e.id) }),

    ShareIssueRegistration: (payload: unknown[]) =>
      shareIssueRegistrationActions.manyLoaded({ payload: plainToInstance(ShareIssueRegistration, payload) }),

    ShareIssueDocument: (payload: unknown[]) =>
      shareIssueDocumentsActions.manyLoaded({
        payload: plainToInstance(ShareIssueDocument, payload),
      }),

    ShareIssueBoardMember: (payload: unknown[]) =>
      shareIssueBoardMembersActions.manyLoaded({
        payload: plainToInstance(ShareIssueBoardMember, payload),
      }),
    SibmDeletedResponse: (payload: GqlSibmDeletedResponse[]) =>
      shareIssueBoardMembersActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    ShareIssueParticipant: (payload: unknown[]) =>
      shareIssueParticipantsActions.manyLoaded({
        payload: plainToInstance(ShareIssueParticipant, payload),
      }),
    SipDeletedResponse: (payload: GqlSipDeletedResponse[]) =>
      shareIssueParticipantsActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    Identity: (payload: unknown[]) =>
      identitiesActions.manyLoaded({
        payload: plainToInstance(Identity, payload),
      }),
    User: (payload: unknown[]) => usersActions.manyLoaded({ payload: plainToInstance(User, payload) }),

    Meeting: (payload: unknown[]) => meetingActions.manyLoaded({ payload: plainToInstance(Meeting, payload) }),
    MeetingDeletedResponse: (payload: GqlMeetingDeletedResponse[]) =>
      meetingActions.manyDeleted({ payload: payload.map(e => e.id) }),

    MeetingConcern: (payload: unknown[]) =>
      meetingConcernsActions.manyLoaded({
        payload: plainToInstance(MeetingConcern, payload),
      }),
    McDeletedResponse: (payload: GqlMcDeletedResponse[]) =>
      meetingConcernsActions.manyDeleted({ payload: payload.map(e => e.id) }),

    MeetingDocument: (payload: unknown[]) =>
      meetingDocumentsActions.manyLoaded({
        payload: plainToInstance(MeetingDocument, payload),
      }),

    MeetingParticipant: (payload: unknown[]) =>
      meetingParticipantsActions.manyLoaded({
        payload: plainToInstance(MeetingParticipant, payload),
      }),
    MpDeletedResponse: (payload: GqlMpDeletedResponse[]) =>
      meetingParticipantsActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),
    MpDeletedShareholdersResponse: (payload: GqlMpDeletedShareholdersResponse[]) =>
      meetingParticipantsActions.shareholdersDeleted({
        payload: payload.map(e => e.meetingId),
      }),

    AgendaItem: (payload: unknown[]) =>
      agendaItemsActions.manyLoaded({
        payload: plainToInstance(AgendaItem, payload),
      }),
    AiDeletedResponse: (payload: GqlAiDeletedResponse[]) =>
      agendaItemsActions.manyDeleted({ payload: payload.map(e => e.id) }),

    AgendaItemDecision: (payload: unknown[]) =>
      agendaItemDecisionsActions.manyLoaded({
        payload: plainToInstance(AgendaItemDecision, payload),
      }),
    AidDeletedResponse: (payload: GqlAidDeletedResponse[]) =>
      agendaItemDecisionsActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    WarrantParticipant: (payload: unknown[]) =>
      warrantParticipantsActions.manyLoaded({
        payload: plainToInstance(WarrantProgramParticipant, payload),
      }),
    WppDeletedResponse: (payload: GqlWppDeletedResponse[]) =>
      warrantParticipantsActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    WarrantBoardMember: (payload: unknown[]) =>
      warrantBoardMembersActions.manyLoaded({
        payload: plainToInstance(WarrantBoardMember, payload),
      }),
    WpbmDeletedResponse: (payload: GqlWpbmDeletedResponse[]) =>
      warrantBoardMembersActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    MeetingAgendaTemplate: (payload: unknown[]) =>
      meetingAgendaTemplatesActions.manyLoaded({
        payload: plainToInstance(MeetingAgendaTemplate, payload),
      }),
    MatDeletedResponse: (payload: GqlMatDeletedResponse[]) =>
      meetingAgendaTemplatesActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    WarrantShareholder: (payload: unknown[]) =>
      warrantShareholdersActions.manyLoaded({
        payload: plainToInstance(WarrantShareholder, payload),
      }),
    WshDeletedResponse: (payload: GqlWshDeletedResponse[]) =>
      warrantShareholdersActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    WarrantDocument: (payload: unknown[]) =>
      warrantProgramDocumentsActions.manyLoaded({
        payload: plainToInstance(WarrantProgramDocument, payload),
      }),

    MeetingAgendaTemplateItem: (payload: unknown[]) =>
      meetingAgendaTemplateItemsActions.manyLoaded({
        payload: plainToInstance(MeetingAgendaTemplateItem, payload),
      }),
    MatiDeletedResponse: (payload: GqlMatiDeletedResponse[]) =>
      meetingAgendaTemplateItemsActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    Shareholder: (payload: unknown[]) =>
      shareholdersActions.manyLoaded({
        payload: plainToInstance(Shareholder, payload),
      }),
    ShDeletedResponse: (payload: GqlShDeletedResponse[]) =>
      shareholdersActions.manyDeleted({ payload: payload.map(e => e.id) }),

    ArticlesOfAssoc: (payload: unknown[]) =>
      articlesOfAssocsActions.manyLoaded({
        payload: plainToInstance(ArticlesOfAssoc, payload),
      }),
    AoaDeletedResponse: (payload: GqlAoaDeletedResponse[]) =>
      articlesOfAssocsActions.manyDeleted({ payload: payload.map(e => e.id) }),

    ArticlesOfAssocDocument: (payload: unknown[]) =>
      articlesOfAssocDocumentsActions.manyLoaded({
        payload: plainToInstance(ArticlesOfAssocDocument, payload),
      }),

    AuthorizationGroup: (payload: unknown[]) =>
      authorizationGroupActions.manyLoaded({
        payload: plainToInstance(AuthorizationGroup, payload),
      }),
    AgDeletedResponse: (payload: GqlAgDeletedResponse[]) =>
      authorizationGroupActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    AuthorizationGroupDocument: (payload: unknown[]) =>
      authorizationGroupDocumentsActions.manyLoaded({
        payload: plainToInstance(AuthorizationGroupDocument, payload),
      }),

    Authorization: (payload: unknown[]) =>
      authorizationsActions.manyLoaded({
        payload: plainToInstance(Authorization, payload),
      }),
    AuthDeletedResponse: (payload: GqlAuthDeletedResponse[]) =>
      authorizationsActions.manyDeleted({ payload: payload.map(e => e.id) }),

    SnrAuthorization: (payload: unknown[]) =>
      snrAuthorizationsActions.manyLoaded({
        payload: plainToInstance(SnrAuthorization, payload),
      }),
    SnrCase: (payload: unknown[]) => snrCasesActions.manyLoaded({ payload: plainToInstance(SnrCase, payload) }),
    SnrBond: (payload: unknown[]) => snrBondsActions.manyLoaded({ payload: plainToInstance(SnrBond, payload) }),

    CompanyDirector: (payload: unknown[]) =>
      companyDirectorsActions.manyLoaded({
        payload: plainToInstance(CompanyDirector, payload),
      }),

    SnrShareCapitalChange: (payload: unknown[]) =>
      snrShareCapitalChangesActions.manyLoaded({
        payload: plainToInstance(SnrShareCapitalChange, payload),
      }),

    OptionsRegistration: (payload: GqlOptionsRegistration[]) =>
      optionsRegistrationActions.manyLoaded({
        payload: plainToInstance(OptionsRegistration, payload),
      }),
    OrDeletedResponse: (payload: GqlOrDeletedResponse[]) =>
      optionsRegistrationActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    OptionsRegistrationSignatory: (payload: GqlOptionsRegistrationSignatory[]) =>
      optionsRegistrationSignatoryActions.manyLoaded({
        payload: plainToInstance(OptionsRegistrationSignatory, payload),
      }),
    OrSignatoryDeletedResponse: (payload: GqlOrSignatoryDeletedResponse[]) =>
      optionsRegistrationSignatoryActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    OptionsSharesRegistration: (payload: GqlOptionsSharesRegistration[]) =>
      optionsSharesRegistrationActions.manyLoaded({
        payload: plainToInstance(OptionsSharesRegistration, payload),
      }),
    OsrDeletedResponse: (payload: GqlOsrDeletedResponse[]) =>
      optionsSharesRegistrationActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    OptionsSharesSubscriber: (payload: GqlOptionsSharesSubscriber[]) =>
      optionsSharesSubscribersActions.manyLoaded({
        payload: plainToInstance(OptionsSharesSubscriber, payload),
      }),
    OssDeletedResponse: (payload: GqlOssDeletedResponse[]) =>
      optionsSharesSubscribersActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    // @ts-expect-error TS2322
    WiseDependencyBond: (payload: GqlWiseDependencyBond[]) => wiseDependencyBondsActions.manyLoaded({ payload }),
    WdbDeletedResponse: (payload: GqlWdbDeletedResponse[]) =>
      wiseDependencyBondsActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),
    WdbResetResponse: (payload: GqlWdbResetResponse[]) =>
      wiseDependencyBondsActions.manyDeleted({
        payload: payload.flatMap(r => r.deletedIds),
      }),

    OptionsSubscriberRepresentative: (payload: GqlOptionsSubscriberRepresentative[]) =>
      optionsSubscriberRepresentativeActions.manyLoaded({
        payload: plainToInstance(OptionsSubscriberRepresentative, payload),
      }),
    OptionsSubscriberRepresentativeDeletedResponse: (payload: GqlOptionsSubscriberRepresentativeDeletedResponse[]) =>
      optionsSubscriberRepresentativeActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    OptionsSharesSubscriberRepresentative: (payload: GqlOptionsSharesSubscriberRepresentative[]) =>
      optionsSharesSubscriberRepresentativeActions.manyLoaded({
        payload: plainToInstance(OptionsSharesSubscriberRepresentative, payload),
      }),
    OptionsSharesSubscriberRepresentativeDeletedResponse: (
      payload: GqlOptionsSubscriberRepresentativeDeletedResponse[],
    ) =>
      optionsSharesSubscriberRepresentativeActions.manyDeleted({
        payload: payload.map(e => e.id),
      }),

    NiceFounder: (payload: GqlNiceFounder[]) =>
      niceFoundersActions.manyLoaded({ payload: plainToInstance(NiceFounder, payload) }),
    NiceFounderDeletedResponse: (payload: GqlNiceFounderDeletedResponse[]) =>
      niceFoundersActions.manyDeleted({ payload: payload.map(e => e.id) }),
  };

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private snackBar: MatSnackBar,
  ) {}

  storeQueryResult(data: Record<string, Data[] | Data>) {
    const store: Record<string, unknown[]> = {};
    const traverse = (nodes: Data[]) => {
      forEach(nodes, node => {
        let cleanNode = node;
        forEach(cleanNode, (v, k) => {
          if (isObject(v) && has(v, '__typename')) {
            traverse([v as Data]);
            cleanNode = omit(cleanNode, [k]) as Data;
          } else if (isArray(v)) {
            traverse(v);
          }
        });
        if (has(this.actions, cleanNode?.__typename)) {
          const a = get(store, cleanNode.__typename, []);
          // @ts-expect-error TS2345
          a.push(node);
          store[cleanNode.__typename] = a;
        }
      });
    };
    const nodes = values(data)[0];
    // @ts-expect-error TS2345
    traverse(isArray(nodes) ? nodes : [nodes]);
    forEach(store, (v, k) => {
      const action = get(this.actions, k);
      if (!isNil(action)) {
        this.store.dispatch(action(v));
      }
    });
  }
}
