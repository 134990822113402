import { useState } from 'react';
import {
  Box,
  Divider,
  Link,
  MenuItem,
  ListItemDecorator,
  ProfileMenu,
  SettingsIcon,
  OpenInNewIcon,
  DeleteIcon,
  ShieldIcon,
} from '@startuptools/ui';
import { UserProfileDialog } from '../../user/settings/UserProfileDialog';
import { AdminDialog } from '../../admin/AdminDialog';
import { Trans, useLingui } from '@lingui/react';
import { GqlUser } from '../../graphql/react-operations';

export const Menu = ({
  switchLang,
  showDeleteCompanyDialog,
  user,
}: {
  switchLang: (lang: string) => void;
  showDeleteCompanyDialog?: () => void;
  user: Pick<GqlUser, 'name' | 'emailMd5' | 'id' | 'admin'>;
}) => {
  const { i18n } = useLingui();
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [isAdminDialogOpen, setIsAdminDialogOpen] = useState(false);

  if (i18n.locale !== 'sv' && i18n.locale !== 'en') {
    throw new Error('languages other than en and sv are not supported');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ProfileMenu
        user={{
          name: user.name,
          emailMd5: user.emailMd5,
          id: user.id,
        }}
        switchLang={switchLang}
        lang={i18n.locale}
      >
        <Divider />
        <MenuItem
          onClick={() => {
            setIsProfileDialogOpen(true);
          }}
        >
          <ListItemDecorator>
            <SettingsIcon />
          </ListItemDecorator>
          <Trans id="Settings" />
        </MenuItem>

        <MenuItem>
          <ListItemDecorator>
            <OpenInNewIcon />
          </ListItemDecorator>
          <Link
            href="https://bolago.com/se/villkor-for-startuptools-plattform-2024"
            underline="none"
            color="neutral"
            target="_blank"
            rel="noreferrer"
          >
            <Trans id="Terms of Service" />
          </Link>
        </MenuItem>
        <Divider />
        {user.admin && showDeleteCompanyDialog && (
          <>
            <MenuItem onClick={showDeleteCompanyDialog}>
              <ListItemDecorator>
                <DeleteIcon />
              </ListItemDecorator>
              <Trans id="Delete Company" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => setIsAdminDialogOpen(true)}>
              <ListItemDecorator>
                <ShieldIcon />
              </ListItemDecorator>
              <Trans id="Admin" />
            </MenuItem>
          </>
        )}
      </ProfileMenu>

      {isProfileDialogOpen && <UserProfileDialog onClose={() => setIsProfileDialogOpen(false)} />}
      {isAdminDialogOpen && <AdminDialog onClose={() => setIsAdminDialogOpen(false)} />}
    </Box>
  );
};
