import { Alert, AlertProps, Button } from '../';
import { Trans } from '@lingui/react';

export const GenericErrorText = () => <Trans id="Something went wrong. Please try again, or contact support." />;
export const GenericError = (props: Omit<AlertProps, 'children'>) => {
  return (
    <Alert color="danger" {...props}>
      <GenericErrorText />
    </Alert>
  );
};

export const NetworkError = ({ resetError, ...props }: Omit<AlertProps, 'children'> & { resetError: () => void }) => {
  return (
    <Alert
      color="danger"
      {...props}
      endDecorator={
        <Button onClick={resetError}>
          <Trans id="Retry" />
        </Button>
      }
    >
      <Trans id="Your network appears to be offline." />
    </Alert>
  );
};

export const NotFoundError = () => {
  return (
    <Alert color="warning">
      <Trans id="We couldn't find that page! Are you sure the link is spelled correctly?" />
    </Alert>
  );
};
