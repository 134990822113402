import { Transform } from 'class-transformer';
import { isNil } from 'lodash-es';
import { DateTime } from 'luxon';

import { Authorization } from './authorization.model';
import { Identity } from './identity.model';
import { McWithIncludes } from './meeting-concern.model';
import { GqlAuthorizationGroup } from '../graphql/operations';

export enum AuthKind {
  Issue,
  Convertibles,
  Warrants,
  Bonds,
}

export const authKindText = {
  [AuthKind.Issue]: $localize`Nyemission`,
  [AuthKind.Warrants]: $localize`Teckningsoptioner`,
  [AuthKind.Convertibles]: $localize`Konvertibler`,
  [AuthKind.Bonds]: $localize`Konvertibler`,
};

export interface AgWithIncludes {
  model: AuthorizationGroup;
  auths: Authorization[];
  signatory: Identity;
  contact: Identity;
  meetingConcern?: McWithIncludes;
}

export class AuthorizationGroup
  implements Omit<GqlAuthorizationGroup, 'authorizationGroupDocuments' | 'authorizations' | 'company'>
{
  id: string;
  companyId: string;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  validThruDate?: DateTime;
  validThruAgmYear?: string;
  snrAuthorizationId?: string;
  snrCaseId?: string;
  contactId?: string;
  signatoryId?: string;
  noBoardStatement: boolean;
  primaryLang: boolean;
  secondaryLang: boolean;
  regFormPaymentThroughBolago: boolean;
  defaultFilename: string;

  @Transform(({ value }: { value: string }) => JSON.parse(value), {
    toClassOnly: true,
  })
  authorizationKinds: { kind: AuthKind }[];

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<AuthorizationGroup>) {
    Object.assign(this, seed);
  }
}
