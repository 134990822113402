import type * as Types from '../../graphql/base-types.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GqlSideNavUserQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GqlSideNavUserQuery = { user: { id: string, name?: string | null, email?: string | null, emailMd5?: string | null, admin: boolean } };


export const SideNavUserDocument = gql`
    query SideNavUser($id: ID!) {
  user(id: $id) {
    id
    name
    email
    emailMd5
    admin
  }
}
    `;

/**
 * __useSideNavUserQuery__
 *
 * To run a query within a React component, call `useSideNavUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSideNavUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSideNavUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSideNavUserQuery(baseOptions: Apollo.QueryHookOptions<GqlSideNavUserQuery, GqlSideNavUserQueryVariables> & ({ variables: GqlSideNavUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlSideNavUserQuery, GqlSideNavUserQueryVariables>(SideNavUserDocument, options);
      }
export function useSideNavUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlSideNavUserQuery, GqlSideNavUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlSideNavUserQuery, GqlSideNavUserQueryVariables>(SideNavUserDocument, options);
        }
export function useSideNavUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GqlSideNavUserQuery, GqlSideNavUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GqlSideNavUserQuery, GqlSideNavUserQueryVariables>(SideNavUserDocument, options);
        }
export type SideNavUserQueryHookResult = ReturnType<typeof useSideNavUserQuery>;
export type SideNavUserLazyQueryHookResult = ReturnType<typeof useSideNavUserLazyQuery>;
export type SideNavUserSuspenseQueryHookResult = ReturnType<typeof useSideNavUserSuspenseQuery>;
export type SideNavUserQueryResult = Apollo.QueryResult<GqlSideNavUserQuery, GqlSideNavUserQueryVariables>;