import React from 'react';
import { BigNumber } from 'bignumber.js';
import { isNil } from 'lodash-es';

interface Props extends Pick<Intl.NumberFormatOptions, 'maximumFractionDigits' | 'minimumFractionDigits'> {
  locale: 'sv' | 'en' | string;
  variant?: 'number' | 'percentage' | 'shares' | 'filesize';
  children: string | number | BigNumber | null | undefined;
  currency?: never;
  renderZero?: boolean;
}

interface PropsCurrency extends Omit<Props, 'variant' | 'currency'> {
  variant: 'currency';
  currency: NonNullable<Intl.NumberFormatOptions['currency']>;
}

const units = ['byte', 'kilobyte', 'megabyte', 'gigabyte'] as const;
function readableFileSize(sizeInBytes: number) {
  let unitIndex = 0;
  let size = sizeInBytes;
  while (size >= 1000 && unitIndex < units.length) {
    size /= 1000;
    unitIndex++;
  }
  return { unit: units[unitIndex], size };
}

export const NumberFormat = ({
  variant = 'number',
  locale,
  children,
  minimumFractionDigits,
  maximumFractionDigits,
  currency,
  renderZero = true,
}: Props | PropsCurrency) => {
  let inputNum!: number;

  if (typeof children === 'string') {
    inputNum = Number(children);
  } else if (typeof children === 'number') {
    inputNum = children;
  } else if (children instanceof BigNumber) {
    inputNum = children.toNumber();
  } else if (children !== undefined && children !== null) {
    throw new Error('child was not string | number | BigNumber | null | undefined');
  }

  const formatted = React.useMemo(() => {
    if (inputNum === 0) {
      return '0';
    }

    const options: Intl.NumberFormatOptions = {};
    let outputNum = inputNum;

    if (variant === 'percentage') {
      if (Math.sign(inputNum) > -1 && inputNum < 0.01) {
        options.minimumFractionDigits = Math.max(0, 1 - Math.floor(Math.log10(inputNum * 100)));
        options.maximumFractionDigits = 1 - Math.floor(Math.log10(inputNum * 100));
      } else {
        options.minimumFractionDigits = minimumFractionDigits ?? 0;
        options.maximumFractionDigits = maximumFractionDigits ?? 2;
      }
      outputNum = inputNum * 100;
    } else if (variant === 'currency') {
      options.minimumFractionDigits = minimumFractionDigits ?? 2;
      options.maximumFractionDigits = maximumFractionDigits ?? minimumFractionDigits ?? 2;
      options.currency = currency;
      options.style = 'currency';
    } else if (variant === 'shares') {
      options.minimumFractionDigits ??= 0;
      options.maximumFractionDigits ??= 0;
    } else if (variant === 'filesize') {
      options.minimumFractionDigits ??= 0;
      options.maximumFractionDigits ??= 0;
      const { size, unit } = readableFileSize(inputNum);
      outputNum = size;
      options.style = 'unit';
      options.unit = unit;
    } else {
      if (inputNum % 1 !== 0) {
        options.minimumFractionDigits = minimumFractionDigits ?? 2;
      }
      options.minimumFractionDigits =
        maximumFractionDigits === 0 ? 0 : isNil(minimumFractionDigits) ? 2 : minimumFractionDigits;
      options.maximumFractionDigits = maximumFractionDigits ?? 6;
    }

    const formattedNumber = Intl.NumberFormat(locale, options).format(outputNum);
    return formattedNumber;
  }, [inputNum, variant, locale, minimumFractionDigits, maximumFractionDigits, currency]);

  if (children === null || children === undefined) {
    return null;
  }

  if (formatted === '0' && !renderZero) {
    return null;
  }

  if (variant === 'percentage') {
    return `${formatted} %`;
  }

  return formatted;
};
