import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ReactWrapperComponent } from '../react-wrapper/react-wrapper.component';
import { SideNav } from './SideNav';
import { SubSink } from 'subsink';
import { selectCompany } from '../../store/selectors/companies.base';
import { Store, select } from '@ngrx/store';
import { catchError, distinctUntilChanged, filter, first, of, tap, throwError } from 'rxjs';
import { AppState } from '../../store/reducers';
import { CommonModule } from '@angular/common';
import { isNil } from 'lodash-es';
import { AlertConfirmDialogComponent } from '../simple-dialogs/alert-confirm-dialog.component';
import { GqlDispatchService } from '../../injectables/gql-dispatch.service';
import { MatDialog } from '@angular/material/dialog';
import { companiesActions } from '../../store/actions/companies.actions';
import { GqlCompanyDeleteService } from '../../graphql/operations';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  standalone: true,
  selector: 'app-side-nav',
  template: `
    <app-react-wrapper
      [component]="SideNav"
      [companyId]="(company$ | async)?.id"
      [props]="{ navigate, showDeleteCompanyDialog }"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactWrapperComponent, CommonModule],
})
export class SideNavWrapperComponent implements OnDestroy {
  SideNav = SideNav;
  subs = new SubSink();
  public navigate = new EventEmitter<string>();

  public company$ = this.store.pipe(
    select(selectCompany),
    distinctUntilChanged(),
    filter(e => !isNil(e)),
  );

  showDeleteCompanyDialog = () => {
    this.company$.pipe(first()).subscribe(company => {
      AlertConfirmDialogComponent.open(this.dialog, {
        cancel: $localize`Avbryt`,
        confirm: $localize`Ta bort företaget`,
        title: $localize`Ta bort ${company.name}`,
        question: $localize`Är du säker på att du vill ta bort företaget?`,
        text: $localize`Företaget och alla data kopplat till det kommer att tas bort permanent`,
        onSubmit: confirmed => {
          if (confirmed) {
            return this.gqlDispatch.mutate(this.gqlCompanyDelete, { companyId: company.id }).pipe(
              tap(() => {
                localStorage.removeItem('last_company_id');
                this.store.dispatch(companiesActions.oneDeleted({ payload: company.id }));
                void this.router.navigate(['/']);
                this.snackbar.open(`${company.name} togs bort permanent`, undefined, { duration: 5000 });
              }),
              catchError(err => {
                console.error(err);
                this.snackbar.open($localize`Misslyckades att ta bort företaget`, undefined, { duration: 5000 });
                return throwError(() => err);
              }),
            );
          }
          return of(null);
        },
      });
    });
  };

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private gqlDispatch: GqlDispatchService,
    private gqlCompanyDelete: GqlCompanyDeleteService,
    activeRoute: ActivatedRoute,
  ) {
    this.subs.sink = this.navigate.asObservable().subscribe(route => {
      void router.navigate(route.split('/'), { relativeTo: activeRoute.root });
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
