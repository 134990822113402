import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactWrapperComponent } from '../components/react-wrapper/react-wrapper.component';
import { Auth } from '@startuptools/auth';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

@Component({
  selector: 'app-auth-wrapper',
  standalone: true,
  imports: [CommonModule, ReactWrapperComponent],
  template: `
    <app-react-wrapper
      [component]="Auth"
      [className]="'overflow-hidden'"
      [props]="{ imgSrc, appPage }"
      [apolloClient]="apolloClient"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthWrapperComponent {
  @Input() public imgSrc!: string;
  @Input() public appPage!: boolean;
  @Input() public apolloClient!: ApolloClient<NormalizedCacheObject>;

  public Auth = Auth;
}
