import type * as Types from '../base-types.graphql';

import { gql } from '@apollo/client';
export type GqlScFieldsFragment = { name: string, voteWeight: string, quantity?: string | null };

export type GqlScWithMinMaxFieldsFragment = { name: string, voteWeight: string, quantity?: string | null, min: string, max: string };

export const ScFieldsFragmentDoc = gql`
    fragment ScFields on ShareClass {
  name
  voteWeight
  quantity
}
    `;
export const ScWithMinMaxFieldsFragmentDoc = gql`
    fragment ScWithMinMaxFields on ShareClassWithMinMax {
  name
  voteWeight
  quantity
  min
  max
}
    `;