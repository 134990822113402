import { PaymentAccountKind } from '@startuptools/common/common';
import { Transform } from 'class-transformer';
import { isNil } from 'lodash-es';
import { BigNumber } from 'bignumber.js';
import { DateTime } from 'luxon';
import {
  GqlAgreementStatus,
  GqlRegistrationStatus,
  GqlWarrantProgram,
  GqlDecisionStatus,
  GqlWarrantProgramKind,
  GqlCurrency,
  GqlRiksbankenObservations,
  GqlDeletable,
} from '../graphql/operations';
import { McWithIncludes } from './meeting-concern.model';
import { WarrantShareholder } from './warrant-shareholder.model';
import { WpbmWithIncludes } from './warrant-board-member.model';
import { PaymentKind } from '@startuptools/common/participants';
import { OptionsRegistration } from './options-registration.model';
import { GqlBoardsStatementOption, GqlShareClassWithMinMax } from '../graphql/base-types.graphql';

export interface WpWithIncludes {
  model: WarrantProgram;
  shareholders?: WarrantShareholder[];
  boardMembers?: WpbmWithIncludes[];
  optionsRegistrations: OptionsRegistration[];
  mc?: McWithIncludes;
}

export enum ValuationMethod {
  Issue,
  Assets,
  ManualIssue,
  Other,
}

export type WarrantProgramId = string;

export class WarrantProgram
  implements
    Omit<
      GqlWarrantProgram,
      | 'company'
      | 'meetingConcern'
      | 'niceFounders'
      | 'optionsRegistrations'
      | 'warrantBoardMembers'
      | 'wiseDependencyBonds'
      | 'optionsSharesRegistrations'
      | 'warrantDocuments'
      | 'warrantShareholders'
    >
{
  id: WarrantProgramId;
  name?: string;
  primaryLang: boolean;
  secondaryLang: boolean;
  description: string;
  warrantKind: GqlWarrantProgramKind;
  boardsStatementOption?: GqlBoardsStatementOption;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  boardsStatementDate?: DateTime;
  boardsStatementSv?: string;
  boardsStatementEn?: string;
  valuationCompanyValue?: string;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  valuationDate?: DateTime;
  maxNumberOfOptions?: string;
  valuationNumberOfShares?: string;
  volatility?: string;
  volatilityDate?: string;
  volatilityDocument?: string;
  strikePrice?: string;
  strikePriceFromQuotaValue?: boolean;
  paymentAccountKind: PaymentAccountKind;
  paymentAccountNumber?: string;
  paymentName?: string;
  ibanNumber?: string;
  ibanBic?: string;
  ibanBank?: string;
  ibanAddress?: string;
  paymentKindCash: boolean;
  paymentKindOffset: boolean;
  paymentKindNonCash: boolean;
  invoiceKind?: string;
  invoiceAddressJson?: string;
  serviceKind?: number;
  bussinessStartDate?: string;
  turnoverOk?: boolean;
  assetsOk?: boolean;
  notListedOk?: boolean;
  practiceOk?: boolean;
  archived: boolean;
  deletable: GqlDeletable;
  governmentOwnershipOk?: boolean;
  valuationMethod?: ValuationMethod;
  agreementStatus: GqlAgreementStatus;
  registrationStatus: GqlRegistrationStatus;
  decisionStatus: GqlDecisionStatus;
  currency: GqlCurrency;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  payBeforeDate?: DateTime;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  warrantStartDate: DateTime | null | undefined;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  warrantSubscribeEndDate?: DateTime;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  warrantEndDate?: DateTime;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  shareSubscribeStartDate?: DateTime;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  shareSubscribeEndDate?: DateTime;
  shareholdersAgreementKind?: number;
  riskfreeInterestRate: string;
  riksbankenRates?: GqlRiksbankenObservations[];
  useRiksbankenRate: boolean;
  pricePerWarrant: string;
  illiquidityDiscount: string;
  discountPricePerWarrant: string;
  valuationShareIssueId?: string;
  valuationSccId?: string;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  paidForAt?: DateTime;
  stripeInvoiceId?: string;
  nonCashStatementPl?: string;
  nonCashStatementSl?: string;
  shareClasses: GqlShareClassWithMinMax[];
  wiseFullyDiluted?: boolean;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  wiseDepBondsRecordDay?: DateTime;
  wiseCeil?: string;
  wiseFloor?: string;
  antlerWise: boolean;
  warrantsForQeso: boolean;
  minRoundSizeAmount?: string;

  companyId: string;
  snrAuthorizationId?: string;
  signatoryId?: string;
  snrBondId?: string;
  snrCaseId?: string;
  contactId?: string;
  usesStepper: boolean;
  useNetStrike: boolean;
  forEmployees: boolean;
  forBoardMembers: boolean;
  forAdvisors: boolean;

  readonly createdAt: string;
  readonly updatedAt: string;

  // @ts-expect-error TS2366
  // eslint-disable-next-line getter-return
  get pricePerShare(): number {
    if (!isNil(this.valuationCompanyValue) && !isNil(this.valuationNumberOfShares)) {
      return new BigNumber(this.valuationCompanyValue).div(this.valuationNumberOfShares).toNumber();
    }
  }

  // @ts-expect-error TS2366
  // eslint-disable-next-line getter-return
  get durationInMonths(): number {
    const start = this.warrantStartDate?.startOf('day');
    const end = this.warrantEndDate?.startOf('day');
    if (!isNil(start) && !isNil(end)) {
      return Math.round(end.diff(start, 'months', { conversionAccuracy: 'longterm' }).months);
    }
  }

  get paymentKinds() {
    return [
      this.paymentKindCash ? PaymentKind.Cash : null,
      this.paymentKindOffset ? PaymentKind.Offset : null,
      this.paymentKindNonCash ? PaymentKind.NonCash : null,
    ].filter(e => !isNil(e));
  }

  constructor(seed?: Partial<WarrantProgram>) {
    Object.assign(this, seed);
  }
}
