import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { isBoolean, isEmpty } from 'lodash-es';
import { first, firstValueFrom, map } from 'rxjs';

import { AuthGuard } from './auth.guard';
import { GqlCompaniesService, GqlCurrentUserService } from '../graphql/operations';

@Injectable({ providedIn: 'root' })
export class RootGuard {
  constructor(
    private router: Router,
    private authGuard: AuthGuard,
    private gqlCompanies: GqlCompaniesService,
    private gqlUser: GqlCurrentUserService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const auth = await firstValueFrom(this.authGuard.canActivate(route));
    if (!isBoolean(auth)) {
      return auth;
    }
    const user = await firstValueFrom(
      this.gqlUser.fetch(undefined, { fetchPolicy: 'no-cache' }).pipe(
        first(),
        map(res => res.data.currentUser),
      ),
    );
    if (isEmpty(user.email) || isEmpty(user.name)) {
      return this.router.createUrlTree(['/onboarding', 'profile']);
    }
    const companies = await firstValueFrom(
      this.gqlCompanies.fetch(undefined, { fetchPolicy: 'no-cache' }).pipe(
        first(),
        map(res => res.data.companies),
      ),
    );
    if (companies.length === 0) {
      return this.router.createUrlTree(['/onboarding']);
    }
    let companyId = localStorage.getItem('last_company_id');
    if (isEmpty(companyId) || !companies.some(c => c.id === companyId)) {
      // @ts-expect-error TS2532
      companyId = companies[0].id;
    }
    return this.router.createUrlTree(['/company', companyId, 'about', 'dashboard']);
  }
}
